import { createStore } from 'vuex';

export default createStore({
  state: {
    token: localStorage.getItem('jwt') || null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('jwt', token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('jwt');
    }
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearToken');
    }
  },
  getters: {
    isLoggedIn: state => !!state.token
  }
});
