<template>
    <div>
      <h1>Yaga Dashboard</h1>
      <!-- Add your dashboard content here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'YagaDashboard'
  };
  </script>
  
  <style scoped>
  /* Add styles here */
  </style>
  