<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="login-card">
      <v-card-title class="headline text-center">Sign-in</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model:valid="valid">
          <v-text-field
            v-model="username"
            label="Username"
            :rules="[rules.required]"
            required
            class="text-field"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            :rules="[rules.required]"
            required
            class="text-field"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="actions-container">
        <v-spacer></v-spacer>
        <v-btn text @click="close" class="action-button">Cancel</v-btn>
        <v-btn text @click="login" class="action-button">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoginDialog',
  data() {
    return {
      dialog: false,
      username: '',
      password: '',
      valid: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    login() {
      if (this.$refs.form.validate && this.$refs.form.validate()) {
        this.$axios.post('/yaga-auth/login', {
          username: this.username,
          password: this.password
        })
        .then(response => {
          this.$store.dispatch('login', response.data.access_token);
          this.$router.push('/YagaDashboard');
          this.close();
        })
        .catch(error => {
          console.error('Login error:', error);
          this.$toast.error('Invalid credentials');
        });
      }
    }
  }
};
</script>

<style scoped>
.login-card {
  background-color: black;
  color: white;
}
.text-center {
  text-align: center;
}
.actions-container {
  display: flex;
  justify-content: center;
}
.action-button {
  background-color: black;
  color: white;
  border: 1px solid white;
  border-radius: 50px;
  text-transform: none;
  font-size: 1.25rem;
  letter-spacing: 0; 
}
.action-button:hover {
  border-color: rgb(46, 163, 242);
  color: rgb(46, 163, 242);
}
.text-field {
  --v-input-min-height: 56px;
  font-size: 1.35rem;
}
</style>

  