<template>
  <div>
    <v-container class="main-container" fluid>
      <!-- Here it begins the template section for the left-column tools menu  -->
      <v-app-bar class="custom-app-bar">
        <div class="header-left">
          <v-btn
            icon
            class="custom-button yellow-button"
            @mouseover="showTooltip('New File', $event)"
            @mouseleave="hideTooltip"
            @click="newProjectDialog = true"
          >
            <font-awesome-icon :icon="['fas', 'file']" />
          </v-btn>
          <v-btn
            class="pill-button light-blue-button"
            @mouseover="showTooltip('Save File', $event)"
            @mouseleave="hideTooltip"
          >
            Save
          </v-btn>
          <v-btn
            class="pill-button light-blue-button"
            @mouseover="showTooltip('Open File', $event)"
            @mouseleave="hideTooltip"
          >
            Open
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <img :src="logo" alt="Company Logo" class="company-logo" />
        <v-spacer></v-spacer>
        <div class="header-right">

          <!-- Run/Stop Simulation Button -->
          <v-btn
            icon
            :class="isSimulating ? 'custom-red-button' : 'custom-green-button'"
            @mouseover="showTooltip(isSimulating ? 'Stop Simulation' : 'Run Simulation', $event)"
            @mouseleave="hideTooltip"
            @click="toggleSimulation"
          >
            <font-awesome-icon :icon="[isSimulating ? 'fas' : 'fas', isSimulating ? 'stop' : 'play']" />
          </v-btn>

          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Undo', $event)"
            @mouseleave="hideTooltip"
            @click="undo"
          >
            <font-awesome-icon :icon="['fas', 'undo']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Redo', $event)"
            @mouseleave="hideTooltip"
            @click="redo"
          >
            <font-awesome-icon :icon="['fas', 'redo']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Share File', $event)"
            @mouseleave="hideTooltip"
          >
            <font-awesome-icon :icon="['fas', 'share']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button yellow-button"
            @mouseover="showTooltip('Close File', $event)"
            @mouseleave="hideTooltip"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </v-btn>
        </div>
      </v-app-bar>
      <!-- Here it ends the template section for the header menu -->
      
      <!-- Here it begins the template section for the header menu -->
      <v-container class="inner-container" fluid>
        <v-row no-gutters>
          <v-col cols="3" class="left-column">
            <!-- Tabs Container -->
            <div class="tabs-container">
              <v-tabs
                vertical
                v-model="tab"
                class="full-width-tabs"
                background-color="transparent"
                show-arrows="false"
              >
                <v-tab key="ld_editor">Tools</v-tab>
                <v-tab key="variables">Variables</v-tab>
              </v-tabs>
            </div>
            <!-- Tools Container -->
            <div class="tools-container">
              <v-tabs-items v-model="tab">
                <v-tab-item key="ld_editor">
                  <div v-if="tab === 0">
                    
                    <div class="section-title">CONTACTS</div>
                    <div class="section-line"></div>
                    <v-row>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('Normally OPEN Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_no')"
                      @dragstart="startDrag('contact_no')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="28" y2="36" stroke="#000814" stroke-width="4" />
                        <line x1="44" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                        <line x1="28" y1="18" x2="28" y2="54" stroke="#000814" stroke-width="4" />
                        <line x1="44" y1="18" x2="44" y2="54" stroke="#000814" stroke-width="4" />
                      </svg>
                      </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('Normally CLOSED Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_nc')"
                      @dragstart="startDrag('contact_nc')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="28" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="44" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="28" y1="18" x2="28" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="44" y1="18" x2="44" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="28" y1="50" x2="44" y2="22" stroke="#000814" stroke-width="4"/>
                      </svg>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('One Shot POSITIVE Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_pos')"
                      @dragstart="startDrag('contact_pos')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="26" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="26" y1="18" x2="26" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="18" x2="46" y2="54" stroke="#000814" stroke-width="4"/>
                        <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text>
                      </svg>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('One Shot NEGATIVE Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_neg')"
                      @dragstart="startDrag('contact_neg')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="26" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="26" y1="18" x2="26" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="18" x2="46" y2="54" stroke="#000814" stroke-width="4"/>
                        <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text>
                      </svg>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="section-title">COILS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil')"
                      @dragstart="startDrag('coil')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('SET (Latch) Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil_set')"
                      @dragstart="startDrag('coil_set')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">S</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('RESET (Unlatch) Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil_reset')"
                      @dragstart="startDrag('coil_reset')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">TIMERS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('Timer ON Delay', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_ton')"
                      @dragstart="startDrag('timer_ton')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TON</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('OFF Delay Timer', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_tof')"
                      @dragstart="startDrag('timer_tof')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TOF</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('Timer ON Retentive', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_tonr')"
                      @dragstart="startDrag('timer_tonr')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TONR</text>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">COUNTERS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count UP', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctu')"
                      @dragstart="startDrag('counter_ctu')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T U</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count DOWN', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctd')"
                      @dragstart="startDrag('counter_ctd')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                   <!-- Add ID text  <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count UP/DOWN', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctud')"
                      @dragstart="startDrag('counter_ctud')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C TU D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="25" y1="45" x2="25" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="47" y1="45" x2="47" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col> -->
                  </v-row>

                  <div class="section-title">COMPARATORS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('Equal', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_equ')"
                        @dragstart="startDrag('comparator_equ')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">E Q U</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('NOT Equal', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_neq')"
                        @dragstart="startDrag('comparator_neq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('GREATER Than', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_grt')"
                        @dragstart="startDrag('comparator_grt')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G R T</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('GREATER Than OR Equal To', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_geq')"
                        @dragstart="startDrag('comparator_geq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('LESS Than', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_les')"
                        @dragstart="startDrag('comparator_les')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E S</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('LESS Than OR Equal To', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_leq')"
                        @dragstart="startDrag('comparator_leq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">Math Functions</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Addition', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('add')"
                        @dragstart="startDrag('add')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">A D D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Substraction', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('sub')"
                        @dragstart="startDrag('sub')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">S U B</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Multiplication', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('mul')"
                        @dragstart="startDrag('mul')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">M U L</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Division', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('div')"
                        @dragstart="startDrag('div')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">D I V</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">Logical Operations</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('AND Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_and')"
                        @dragstart="startDrag('logical_and')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">A N D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('OR Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_or')"
                        @dragstart="startDrag('logical_or')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">O R</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('NOT Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_not')"
                        @dragstart="startDrag('logical_not')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N O T</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  </div>
                </v-tab-item>
                <v-tab-item key="variables">
                <div v-if="tab === 1" class="variables-table-wrapper">
                  <v-simple-table class="variables-table">
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Class</th>
                        <th class="text-left">Type</th>
                        <th class="text-left">Value</th>
                        <th class="text-left">CPS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(variable, index) in variables"
                        :key="variable.id"
                        :class="{
                          'highlight-id-class-type-cps': isSelectedElement(variable.id),
                          'highlight-name-value': isSelectedElement(variable.id)
                        }"
                      >
                        <td>{{ variable.id }}</td>
                        <td>
                          <input
                            type="text"
                            v-model="variable.name"
                            @blur="updateVariable(index, variable)"
                            @keyup.enter="updateVariable(index, variable)"
                            maxlength="10"
                            pattern="[A-Za-z0-9-_]+"
                          />
                        </td>
                        <td>{{ variable.class }}</td>
                        <td>{{ variable.type }}</td>
                        <td>
                          <template v-if="variable.type === 'Bool'">
                            <select
                              v-model="variable.value"
                              @change="updateVariable(index, variable)"
                              class="variable-select"
                            >
                              <option value="false">False</option>
                              <option value="true">True</option>
                            </select>
                          </template>
                          <template v-else>
                            <input
                              type="text"
                              v-model="variable.value"
                              @blur="updateVariable(index, variable)"
                              @keyup.enter="updateVariable(index, variable)"
                              maxlength="6"
                              pattern="\d*"
                            />
                          </template>
                        </td>
                        <td>{{ variable.cps }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
      <!-- Here it ends the template section for the left-column tools menu -->

      <!-- Here it begins the template section for the rightt-column ladder logic area -->
          <v-col cols="9" class="ladder-logic-area">
            <div class="ladder-logic-wrapper" ref="ladderLogicArea" @dragover.prevent @drop="handleDrop">
            <div
              v-for="(computedRung, rungIndex) in computedSegments"
              :key="computedRung.id"
              class="rung-wrapper"
              :style="{ height: computedRungHeights[rungIndex] + 'px' }"
            >
              <svg
                class="rung-svg"
                :width="getRungWidth(computedRung.elements?.length || 0, true)"
                :height="computedRungHeights[rungIndex]"
              >
         <!-- Vertical left line -->
         <line
          x1="0"
          y1="40"
          x2="0"
          y2="120"
          :stroke="isSimulating ? '#ffc300' : 'rgb(3, 171, 255)'"  
          stroke-width="12"
        />
        
        <!-- Mini horizontal rung segment -->
        <line
          x1="0"
          y1="80" 
          x2="15" 
          y2="80" 
          :stroke="isSimulating ? '#ffc300' : 'rgb(3, 171, 255)'"  
          stroke-width="4"
        />

        <!-- Horizontal segments -->
        <line
          v-for="segment in computedRung.segments"
          :key="segment.id"
          :x1="segment.x1 + 15" 
          :y1="segment.y1" 
          :x2="segment.x2 + 15" 
          :y2="segment.y2" 
          :stroke="selectedSegment === segment.id && selectedRung === rungIndex ? '#ffc300' : getSegmentColor(rungIndex, segment.id)"
          stroke-width="4"
        />

        <!-- Branch elements -->
        <line
          v-for="element in computedVerticalBranches[rungIndex]"
          :key="element.id"
          :x1="element.position + 15" 
          :y1="element.y1"
          :x2="element.position + 15" 
          :y2="element.y2"
          :stroke="getSegmentColor(rungIndex, element.id)"
          stroke-width="4"
        />

        <!-- Transparent rectangles for regular segments only -->
        <rect
          v-for="segment in nonBranchSegments[rungIndex]"
          :key="'rect-' + segment.id"
          :x="segment.x1 + 15" 
          :y="segment.y1 - 36" 
          width="72"
          height="72"
          fill="transparent"
          @click="selectSegment(segment.id, rungIndex)"
          @dragover.prevent
          @drop="dropOnSegment(segment, rungIndex)"
        />

        <!-- Vertical right line -->
        <line
          :x1="getRungWidth(computedRung.elements?.length || 0, true)" 
          y1="40"
          :x2="getRungWidth(computedRung.elements?.length || 0, true)" 
          y2="120"
          :stroke="getSegmentColor(rungIndex, computedRung.segments[computedRung.segments.length - 1].id)"
          stroke-width="12"
        />


                <g v-for="element in nonBranchElements[rungIndex]" :key="element.id" :transform="'translate(' + (element.position + 15) + ', ' + (element.y1-36) + ')'">>
                  <g @click="isSimulating ? toggleContact(element) : selectElement(element, rungIndex)">
                    <path :d="element.content" :fill="getElementColor(element)" />
                    <rect x="0" y="0" width="72" height="60" fill="transparent" stroke="transparent" stroke-width="1" />
                    <template v-if="element.type === 'contact_no'">
                    <line x1="0" y1="36" x2="28" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="18" x2="28" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="18" x2="44" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'contact_nc'">
                    <line x1="0" y1="36" x2="28" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="18" x2="28" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="18" x2="44" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="50" x2="44" y2="22" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>                  
                  </template>


                  <template v-else-if="element.type === 'contact_pos'">
                  <line x1="0" y1="36" x2="26" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="26" y1="18" x2="26" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="18" x2="46" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                </template>

                <template v-else-if="element.type === 'contact_neg'">
                  <line x1="0" y1="36" x2="26" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="26" y1="18" x2="26" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="18" x2="46" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                </template>

                <template v-else-if="element.type === 'coil'">
                  <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  <text v-if="element.linkedId" x="36" y="75" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>

                </template>

                <template v-else-if="element.type === 'r_coil'">
                  <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  <text v-if="element.linkedId" x="36" y="75" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>

                </template>


                <template v-else-if="element.type === 'coil_set'">
                  <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">S</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  <text v-if="element.linkedId" x="36" y="75" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                </template>

                <template v-else-if="element.type === 'coil_reset'">
                  <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  <text v-if="element.linkedId" x="36" y="75" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                </template>

                <template v-else-if="element.type === 'timer_ton'">
                  <g @click="configureTimer(element)">
                    <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">TON</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                    <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <!-- Preset Time (PT) and Elapsed Time (ET) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                  </g>
                </template>


                <template v-else-if="element.type === 'timer_tof'">
                  <g @click="configureTimer(element)">
                    <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="white" font-size="15" font-weight="bold" stroke-width="1" text-anchor="middle">TOF</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                    <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <!-- Preset Time (PT) and Elapsed Time (ET) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                  </g>
                </template>


                <template v-else-if="element.type === 'timer_tonr'">
                  <g @click="configureTimer(element)">
                    <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">TONR</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                    <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <text v-if="element.resetCoilId" x="36" y="98" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset Time (PT) and Elapsed Time (ET) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                  </g>
                </template>



                <template v-else-if="element.type === 'counter_ctu'">
                  <g @click="configureCounter(element)">
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTU</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CU</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PV</text>
                    <text x="50" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="18" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">R</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="62" x2="9" y2="62" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="49" x2="9" y2="49" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="62" x2="72" y2="62" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <!-- Linked ID text -->
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <text v-if="element.resetCoilId" x="36" y="98" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset and Current Value -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetValue }}</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.currentValue }}</text>
                  </g>
                </template>

                <template v-else-if="element.type === 'counter_ctd'">
                  <g @click="configureCounter(element)">
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 2 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTD</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CD</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PV</text>
                    <text x="50" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="18" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">R</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="62" x2="9" y2="62" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="49" x2="9" y2="49" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="62" x2="72" y2="62" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <!-- Linked ID text -->
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <text v-if="element.resetCoilId" x="36" y="98" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset Value (PV) and Current Value (CV) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetValue }}</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.currentValue }}</text> 
                  </g>
                </template>


                <template v-else-if="element.type === 'counter_ctud'">
                  <g>
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTUD</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CU</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">QU</text>
                    <text x="22" y="50" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CD</text>
                    <text x="50" y="50" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">QD</text>
                    <text x="22" y="60" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">R</text>
                    <text x="50" y="60" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="22" y="70" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">LD</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="45" x2="9" y2="45" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="56" x2="9" y2="56" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="65" x2="9" y2="65" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="45" x2="72" y2="45" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="56" x2="72" y2="56" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <!-- Linked ID text -->
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <text v-if="element.resetId" x="36" y="98" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="reset-id">Reset: {{ element.resetId }}</text>
                  </g>
                </template>


                  <template v-else-if="element.type === 'comparator_equ'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">EQU</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="30" y1="48" x2="40" y2="48" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="30" y1="51" x2="40" y2="51" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_neq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">NEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="30" y1="48" x2="40" y2="48" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="30" y1="51" x2="40" y2="51" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="33" y1="53" x2="38" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_grt'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">GRT</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="53" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="46" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_geq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">GEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="53" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="46" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="55.5" x2="40" y2="51.5" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_les'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">LES</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="49" x2="40" y2="53" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="49" x2="40" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_leq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">LEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="49" x2="40" y2="53" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="49" x2="40" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="40" y1="55.5" x2="31" y2="51.5" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'add'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">ADD</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">+</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'sub'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">SUB</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">-</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'mul'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">MUL</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">x</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'div'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">DIV</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">/</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_and'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">AND</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">AND</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_or'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">OR</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">OR</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_not'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">NOT</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">NOT</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>
                   </g>
                   <foreignObject
                      v-if="(selectedElement === element.id && selectedRung === rungIndex && !isSimulating)"
                      x="60"
                      y="-12"
                      width="16"
                      height="16"
                      class="delete-button-fo"
                    >
                      <div class="delete-button" @click.stop="deleteElement(element.id, rungIndex)">
                        <svg width="16" height="16" viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="12" fill="red" stroke="#000814" stroke-width="1"/>
                          <line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2"/>
                          <line x1="6" y1="18" x2="18" y2="6" stroke="white" stroke-width="2"/>
                        </svg>
                      </div>
                    </foreignObject>
                  </g>
                </svg>
                <foreignObject
                v-if="deleteButtonCondition && selectedRung === rungIndex"
                :style="{ position: 'absolute', top: deleteButtonPosition.top + 'px', left: deleteButtonPosition.left + 'px' }"
                  width="16"
                  height="16"
                  class="delete-button-fo"
                >
                  <div class="delete-button" @click.stop="deleteSelectedBranch(currentElement.position, rungIndex)">
                    <svg width="16" height="16" viewBox="0 0 24 24">
                      <circle cx="12" cy="12" r="12" fill="red" stroke="#000814" stroke-width="1"/>
                      <line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2"/>
                      <line x1="6" y1="18" x2="18" y2="6" stroke="white" stroke-width="2"/>
                    </svg>
                  </div>
                </foreignObject>

                <!-- Place the "+" button dynamically for selected segment/element -->
                <div v-if="showPlusButton && selectedRung === rungIndex" :style="{ position: 'absolute', top: plusButtonPosition.top + 'px', left: plusButtonPosition.left + 'px' }">
                  <v-btn icon class="add-branch-button" @click="addBranch">
                    <v-icon :style="{ color: '#ffc300' }">mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                <!-- Place the "+" button dynamically for extending a branch -->
                <div v-if="showExtendBranchButton && selectedRung === rungIndex" :style="{ position: 'absolute', top: extendBranchButtonPosition.top + 'px', left: extendBranchButtonPosition.left + 'px' }">
                  <v-btn icon class="extend-branch-button" @click="extendBranch">
                    <v-icon :style="{ color: '#ffc300' }">mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-btn icon class="add-rung-button" @click="addRung">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <transition name="fade">
        <div
          v-if="tooltipVisible"
          class="tooltip-box"
          :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
        >
          {{ tooltipText }}
        </div>
      </transition>
    </v-container>
    <!-- Here it ends the template section for the right-column ladder logic area -->

    <!-- Here it begins the template section for the dialog boxes in the LLBuilder -->
    <v-dialog v-model="newProjectDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Start New Project</v-card-title>
        <v-card-text>Do you really want to create a new project?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffc300" text @click="confirmNewProject">Yes, I confirm</v-btn>
          <v-btn color="#03abff" text @click="newProjectDialog = false">No, Cancel Operation</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="portrait-warning">
      <div class="svg-icons">
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5" viewBox="0 0 16 16">
          <path d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm-1 6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2z"/>
          <path d="M14 7.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0"/>
        </svg>
      </div>
      <div class="rotate-message">
        Please rotate your device 
      </div>
      <div class="rotate-message"> 
        to landscape mode
      </div>
      <div class="portrait-warning-logo">
        <img :src="logo" alt="Company Logo"/>
      </div>
    </div>

    <!-- Add Tool Alert Dialog -->
<v-dialog v-model="addToolDialog.visible" persistent max-width="300px">
  <v-card>
    <v-card-title class="headline">Simulation Active</v-card-title>
    <v-card-text>You can't add a tool while in Run Mode</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="addToolDialog.visible = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Add Rung Alert Dialog -->
<v-dialog v-model="addRungDialog.visible" persistent max-width="300px">
  <v-card>
    <v-card-title class="headline">Simulation Active</v-card-title>
    <v-card-text>You can't add a rung while in Run Mode</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="addRungDialog.visible = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Timer Configuration Dialog -->
<v-dialog v-model="showTimerDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Configure Timer</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="dialogPresetTime"
              label="Preset Time (s)"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="dialogLinkedContact"
              :items="availableContacts.map(contact => contact.id)"
              label="Linked Contact"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveTimerSettings">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeTimerDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- Counter Configuration Dialog -->
<v-dialog v-model="showCounterDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Configure Counter</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="dialogPresetValue"
              label="Preset Value"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="dialogLinkedContact"
              :items="availableContacts.map(contact => contact.id)"
              label="Linked Contact"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveCounterSettings">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeCounterDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
   <!-- Here it ends the template section for the dialog boxes in the LLBuilder -->
  <!-- Add Simulation Alert Dialog -->
  <v-dialog v-model="simulationAlertDialog" persistent max-width="300px">
    <v-card>
      <v-card-title class="headline">Simulation Active</v-card-title>
      <v-card-text>You can't perform this action while in Run Mode</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#ffc300" text @click="simulationAlertDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed, onUnmounted } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import logo from '@/assets/Logo_Medusa_Digital_Dark.webp';

export default {
  name: 'LLBuilder',
  components: {
    FontAwesomeIcon,
  },
  setup() {
   //here we have all the setup for the header menu
      //----------------------------------------------------------------
   const newProjectDialog = ref(false);

function confirmNewProject() {
  // Reset the ladder logic to the initial state
  rungs.value = [{ id: 1, elements: [], segments: [] }];
  variables.value = [];
  resetElementCounters(); // Reset the element counters
  updateSegments();
  newProjectDialog.value = false; // Close the dialog
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) }); // Record the state
}
function runSimulation() {
  isSimulating.value = true;
  initializeTimers();
  initializeCounters();

  rungs.value.forEach(rung => {
    rung.elements.forEach(element => {
      if (element.type === 'contact_no') {
        element.state = 0;
      } else if (element.type === 'contact_nc') {
        element.state = 1;
      } else if (element.type === 'contact_pos') {
        element.state = 0;
      } else if (element.type === 'contact_neg') {
        element.state = 1;
      } else if (element.type === 'coil') {
        element.state = 0;
      }  else if (element.type === 'r_coil') {
        element.state = 0;
      } else if (element.type === 'coil_set') {
        element.state = 0;
      } else if (element.type === 'coil_reset') {
        element.state = 0;
      } else if (element.type === 'counter_ctu') {
        element.state = 0;
        element.currentValue = 0; // Reset counter current value

        const counter = counters.counter_ctu.find(c => c.id === element.id);
        if (counter) {
          counter.currentValue = 0; // Ensure counter is also reset
          counter.state = 0;
          counter.previousState = 0;
        }
      } else if (element.type === 'counter_ctd') {
        element.state = 0;
        element.currentValue = element.presetValue; // Initialize CTD counter current value to preset

        const counter = counters.counter_ctd.find(c => c.id === element.id);
        if (counter) {
          counter.currentValue = element.presetValue; // Ensure counter is also initialized
          counter.state = 0;
          counter.previousState = 0;
        }
      }
      element.elapsedTime = 0;
    });
  });

  lastUpdateTime = performance.now();
  startTimerIntervals();
  updateCounters();
  calculateEnergizedStates();
}

function stopSimulation() {
  isSimulating.value = false;
  clearInterval(timerInterval);

  rungs.value.forEach(rung => {
    rung.elements.forEach(element => {
      if (element.type === 'contact_no') {
        element.state = 0;
      } else if (element.type === 'contact_nc') {
        element.state = 1;
      } else if (element.type === 'contact_pos') {
        element.state = 0;
      } else if (element.type === 'contact_neg') {
        element.state = 1;
      } else if (element.type === 'coil') {
        element.state = 0;
      }  else if (element.type === 'r_coil') {
        element.state = 0;
      } else if (element.type === 'coil_set') {
        element.state = 0;
      } else if (element.type === 'coil_reset') {
        element.state = 0;
      } else if (element.type === 'counter_ctu') {
        element.state = 0;
        element.currentValue = 0; // Reset counter current value
      } else if (element.type === 'counter_ctd') {
        element.state = 0;
        element.currentValue = element.presetValue; // Initialize CTD counter current value to preset
      }
      element.elapsedTime = 0;
    });
  });

  updateSegments();
}



   //here we have all the setup for the left-column tool menu
      //----------------------------------------------------------------
    const tooltipText = ref('');
    const tooltipVisible = ref(false);
    const tooltipX = ref(0);
    const tooltipY = ref(0);
    const tooltipTimeout = ref(null);
    const tabIndices = ['ld_editor', 'variables'];
    const tab = ref(0);
    const variables = ref([]);

    function showTooltip(text, event) {
      tooltipTimeout.value = setTimeout(() => {
        tooltipText.value = text;
        tooltipX.value = event.clientX + 5;
        tooltipY.value = event.clientY + 28;
        tooltipVisible.value = true;
      }, 500);
    }

    function showTooltip2(text, event) {
      tooltipTimeout.value = setTimeout(() => {
        tooltipText.value = text;
        tooltipX.value = event.clientX + 0;
        tooltipY.value = event.clientY + 15;
        tooltipVisible.value = true;
      }, 500);
    }

    function hideTooltip() {
      clearTimeout(tooltipTimeout.value);
      tooltipVisible.value = false;
    }

    function createVariableEntry(elementId) {
      const prefix = elementId.replace(/[0-9]/g, '');
      const variableClass = getVariableClass(prefix);
      const variableType = ['NO', 'NC', 'P', 'N', 'C', 'SC', 'RC', 'R_C'].includes(prefix) ? 'Bool' : 'Int';
      const initialValue = variableType === 'Bool' ? false : 0;

      return {
        id: elementId,
        name: elementId,
        class: variableClass,
        type: variableType,
        value: initialValue,
        cps: 'No' // Set default CPS value to 'No'
      };
    }

    function getVariableClass(prefix) {
      switch (prefix) {
        case 'C': return 'Coil';
        case 'R_C': return 'R_Coil';
        case 'NO': return 'Contact';
        case 'NC': return 'Contact';
        case 'P': return 'Contact';
        case 'N': return 'Contact';
        case 'SC': return 'Coil';
        case 'RC': return 'Coil';
        case 'TON': return 'Timer';
        case 'TOF': return 'Timer';
        case 'TONR': return 'Timer';
        case 'CTU': return 'Counter';
        case 'CTD': return 'Counter';
        case 'CTUD': return 'Counter';
        case 'EQU': return 'Comparator';
        case 'NEQ': return 'Comparator';
        case 'GRT': return 'Comparator';
        case 'GEQ': return 'Comparator';
        case 'LES': return 'Comparator';
        case 'LEQ': return 'Comparator';
        case 'ADD': return 'Operation';
        case 'SUB': return 'Operation';
        case 'MUL': return 'Operation';
        case 'DIV': return 'Operation';
        case 'AND': return 'Logical Operation';
        case 'OR': return 'Logical Operation';
        case 'NOT': return 'Logical Operation';
        default: return '';
      }
    }

    function deleteVariable(id) {
      const index = variables.value.findIndex((v) => v.id === id);
      if (index !== -1) {
        variables.value.splice(index, 1);
      }
    }


   //here we have all the setup for the right-column ladder logic area
   //----------------------------------------------------------------

   //+++++++++++++here is script related to rung selection and basic rung addition and rung simulation++++++++++++++
    const selectedSegment = ref(null); // Current selected segment id
    const selectedElement = ref(null); // Track selected element id
    const selectedRung = ref(null); // Track selected rung index
        // Simulation state
    const isSimulating = ref(false);
    const energizedRungs = ref([]);
    const energizedSegments = ref([]);

    const rungs = ref([{ id: 1, elements: [], segments: [] }]);
    const ladderLogicArea = ref(null);
    const ladderLogicWidth = ref(0);

    const rungHeights = ref([]); // This will store the dynamic heights of the rungs


    function pushState(state) {
      // When making a new action, clear all forward states if any exist
      if (history.currentIndex + 1 < history.states.length) {
        history.states.splice(history.currentIndex + 1);
      }
      history.states.push(state);
      history.currentIndex++;
    }

    const simulationAlertDialog = ref(false); // New dialog visibility state

    function undo() {
      if (isSimulating.value) {
        simulationAlertDialog.value = true; // Show dialog
        return;
      }
      if (history.currentIndex > 0) {
        history.currentIndex--;
        applyState(history.states[history.currentIndex]);
      }
    }

    function redo() {
      if (isSimulating.value) {
        simulationAlertDialog.value = true; // Show dialog
        return;
      }
      if (history.currentIndex < history.states.length - 1) {
        history.currentIndex++;
        applyState(history.states[history.currentIndex]);
      }
    }

    function applyState(state) {
      // Here you apply the state to your application
      rungs.value = JSON.parse(JSON.stringify(state.rungs));
      variables.value = JSON.parse(JSON.stringify(state.variables));
      updateSegments();
    }

    function energizeVerticalBranchSegments(rungIndex, lastPosition, segmentX1, segmentX2, energizeVerticalStart, energizeVerticalEnd) {
  const rung = rungs.value[rungIndex];

  // Find relevant vertical branches using lastPosition and segmentX1, segmentX2
  const verticalBranches = rung.elements.filter(element => 
    element.type === 'branch_vertical' &&
    (element.position == segmentX1 || element.position == segmentX2)
  );

  verticalBranches.forEach(branch => {
    // Determine if the branch is start or end based on its ID or position
    const isStartSegment = branch.y1 < branch.y2; // assuming y1 < y2 for start
    // Energize start segment if the condition is met
    if (isStartSegment) {
      branch.state = energizeVerticalStart ? 1 : 0;
    } else {
      branch.state = energizeVerticalEnd ? 1 : 0;
    }
    console.log(`${branch.state === 1 ? 'Energizing' : 'De-energizing'} vertical branch: ${branch.id}`);
  });
}




    const segmentCount = computed(() => {
      if (!ladderLogicWidth.value) return 0; // Ensures there is a valid width
      const width = parseFloat(ladderLogicWidth.value); // Convert width to a number
      console.log('Computed Width:', width);
      const count = Math.floor(width / 72);
      console.log('count:', count);
      return count > 0 ? count : 0; // Prevents negative or zero counts which may cause errors
    });

    onMounted(() => {
  // Initial width update when component mounts
  updateWidth(); 
  selectedRung.value = 0; // Initialize to the first rung if exists

  // Listen for window resize to adjust width
  window.addEventListener('resize', updateWidth);

  // Observe changes in ladderLogicArea to update segments
  const observer = new ResizeObserver(() => {
    updateSegments();
  });
  if (ladderLogicArea.value) {
    observer.observe(ladderLogicArea.value);
  }

  // Push the initial state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });

  // Recalculate rung heights on component mount (initial load)
  calculateRungHeight();

  // Clean up on unmount
  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
    observer.disconnect(); // Clean up observer on unmount
  });
});

    function updateWidth() {
      const area = document.querySelector('.ladder-logic-wrapper');
      if (area) {
        ladderLogicWidth.value = area.offsetWidth; // Set width based on the container's actual size
      }
      updateSegments(); // Ensure segments are updated after resizing
    }

// Watch for changes in window width to update the width and segments
watch(
  () => window.innerWidth,
  () => {
    if (ladderLogicArea.value) {
      ladderLogicWidth.value = ladderLogicArea.value.offsetWidth;
    }
    updateSegments(); // Ensure segments are updated after window resize
  }
);

// Watch for changes in the rungs to update the rung heights dynamically
watch(
  () => rungs.value,
  () => {
    calculateRungHeight(); // Recalculate heights when rungs change
  },
  { deep: true } // Ensure deep watch to capture nested changes
);

watch(
  () => window.innerWidth,
  () => {
    calculateRungHeight(); // Recalculate heights on window resize
  }
);

    
    const computedSegments = computed(() => {
      if (!rungs.value) return [];
      console.log('Computing segments for rungs:', JSON.parse(JSON.stringify(rungs.value)));
      return rungs.value.map((rung, index) => {
        console.log(`Computing segments for rung ${index}:`, JSON.parse(JSON.stringify(rung)));
        const segments = getLineSegments(rung.elements || []);
        console.log(`CS-Segments for rung ${index}:`, JSON.parse(JSON.stringify(segments)));
        return {
          ...rung,
          elements: rung.elements || [],
          segments,
        };
      });
    });

    const filteredSegments = (segments) => {
      return segments.filter((segment) => !isSegmentOverlappingTool(segment));
    };

    const computedWidth = computed(() => {
      // Calculate the necessary width based on the segments
      const widths = computedSegments.value.map((rung) =>
        rung.segments.reduce((max, segment) => Math.max(max, segment.x2), 0)
      );
      // Ensure at least 1000px wide or the maximum segment end
      return Math.max(...widths, 1000);
    });

    function addRung() {
  if (isSimulating.value) {
    addRungDialog.value.visible = true; // Show dialog
    return;
  }

  const newRungId = rungs.value.length + 1;
  rungs.value.push({ id: newRungId, elements: [], segments: [] });
  updateSegments(); // Update segments for all rungs
  console.log('Added new rung:', JSON.parse(JSON.stringify(rungs.value)));

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}


    watch(
      tab,
      (newValue, oldValue) => {
        console.log(
          `Tab changed from ${tabIndices[oldValue]} to ${tabIndices[newValue]}`
        );
        console.log(`Rendering content for tab: ${tabIndices[newValue]}`);
      },
      { immediate: true }
    );

    // Method to update variable when user changes input
    const updateVariable = (index, updatedVariable) => {
      variables.value[index] = { ...variables.value[index], ...updatedVariable };

      // Push the new state to history
      pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
    };

        // Function to calculate height based on content (e.g., number of elements in the rung)
        const calculateRungHeight = () => {
  rungHeights.value = rungs.value.map((rung) => {
    const baseHeight = getRungBaseHeight(rung); // Calculate base height based on elements
    const additionalHeight = 50; // Add extra 50 pixels to ensure no elements are missed
    return baseHeight + additionalHeight;
  });
};


    // Computed property to recalculate heights when the rungs data changes
    const computedRungHeights = computed(() => {
      return rungs.value.map((rung, index) => {
        // Ensure this is reactive to changes in rungs
        return rungHeights.value[index] || 100; // Default to 'auto' if not yet calculated
      });
    });



    const getRungBaseHeight = (rung) => {
  // Base height for an empty rung
  let baseHeight = 65;

  // Height added for each new branch level
  const branchLevelHeight = 60;

  // Initialize max branch level
  let maxBranchLevel = 0;

  // Filter branch elements
  const branchElements = rung.elements.filter(element => 
    element.type === 'branch_vertical' || element.type === 'branch_horizontal'
  );

  // Determine the highest branch level within this rung
  branchElements.forEach(element => {
    if (element.level > maxBranchLevel) {
      maxBranchLevel = element.level;
    }
  });

  // Increase height based on the maximum branch level
  baseHeight += maxBranchLevel * branchLevelHeight;

  // Return the calculated height
  return baseHeight;
};















    function isSegmentOverlappingTool(segment) {
      return rungs.value[selectedRung.value]?.elements.some(
        (element) =>
          element.position <= segment.x1 &&
          element.position + 72 >= segment.x2
      );
    }
    
    function getLineSegments(elements) {
  console.log('Getting line segments for elements:', JSON.parse(JSON.stringify(elements)));
  let segments = [];
  const segmentWidth = 72; // Fixed segment width
  const rungWidth = parseInt(getRungWidth(elements, true)); // Total width of the rung
  const processedPositions = new Set();

  // Sort elements by position to ensure proper segment calculation
  elements.sort((a, b) => a.position - b.position);

  let lastPosition = 0;

  // Handle branch elements separately
  const branchElements = elements.filter(element => element.type === 'branch_vertical' || element.type === 'branch_horizontal');
  const nonBranchElements = elements.filter(element => element.type !== 'branch_vertical' && element.type !== 'branch_horizontal');

  console.log('Non-branch elements:', JSON.parse(JSON.stringify(nonBranchElements)));
  console.log('Branch elements:', JSON.parse(JSON.stringify(branchElements)));

  // Add segments before the first element
  if (nonBranchElements.length > 0) {
    const firstElementPosition = nonBranchElements[0].position;
    console.log('First non-branch element position:', firstElementPosition);

    for (let x = 0; x < firstElementPosition; x += segmentWidth) {
      if (!processedPositions.has(x)) {
        segments.push({
          id: `segment-${x}-${80}`,
          x1: x,
          x2: Math.min(x + segmentWidth, firstElementPosition),
          y1: 80,
          y2: 80,
          state: 1, // Default state
          type: 'segment',
          level: 0,
          isLast: false // Not the last segment
        });
        processedPositions.add(x);
      }
    }
    lastPosition = firstElementPosition;
    console.log('Segments before the first element:', JSON.parse(JSON.stringify(segments)));
  }

  // Add segments for non-branch elements
  nonBranchElements.forEach(element => {
    const start = element.position;
    console.log(`Processing element at position ${start}`);

    // Add segments before the current element if there's space
    if (lastPosition < start) {
      for (let x = lastPosition; x < start; x += segmentWidth) {
        if (!processedPositions.has(x)) {
          segments.push({
            id: `segment-${x}-${80}`,
            x1: x,
            x2: Math.min(x + segmentWidth, start),
            y1: 80,
            y2: 80,
            state: 1, // Default state
            type: 'segment',
            level: 0,
            isLast: false // Not the last segment
          });
          processedPositions.add(x);
        }
      }
    }

    // Update lastPosition to the end of the current element
    lastPosition = start + segmentWidth;
    console.log(`Updated lastPosition to ${lastPosition}`);
  });

  // Handle branches and ensure segment is added if needed
  branchElements.forEach(branch => {
    console.log(`Processing branch at position ${branch.position}`);
    const yOffset = branch.level * 70; // Calculate y offset based on the branch level
    if (branch.type === 'branch_vertical') {
      // Handle vertical branches if needed
    } else if (branch.type === 'branch_horizontal') {
      // Add segments before the branch if there's space
      if (lastPosition < branch.position) {
        for (let x = lastPosition; x < branch.position; x += segmentWidth) {
          if (!processedPositions.has(x)) {
            segments.push({
              id: `segment-${x}-${80}`,
              x1: x,
              x2: Math.min(x + segmentWidth, branch.position),
              y1: 80,
              y2: 80,
              state: 1, // Default state
              type: 'segment',
              level: 0,
              isLast: false // Not the last segment
            });
            processedPositions.add(x);
          }
        }
      }
      // Add rung segments in the place where a branch is added
      for (let x = branch.position; x < branch.position + segmentWidth; x += segmentWidth) {
        if (!processedPositions.has(x)) {
          segments.push({
            id: `segment-${x}-${80}`,
            x1: x,
            x2: Math.min(x + segmentWidth, branch.position + segmentWidth),
            y1: 80,
            y2: 80,
            state: 1, // Default state
            type: 'segment',
            level: 0,
            isLast: false // Not the last segment
          });
          processedPositions.add(x);
          lastPosition = branch.position + segmentWidth;
        }
      }
      // Add horizontal branch segments
      const branchEndPosition = branch.position + segmentWidth;
      segments.push({
        id: `branch-horizontal-${branch.position}-${branch.level}`, // Ensure consistent ID with x and y positions
        x1: branch.position,
        x2: branchEndPosition,
        y1: 78 + yOffset, // Adjust y1 based on level
        y2: 78 + yOffset, // Same y for both points in a horizontal line
        state: 1, // Default state
        type: 'branch_horizontal',
        level: branch.level,
        isLast: false // Not the last segment
      });
      processedPositions.add(branch.position);

      lastPosition = branchEndPosition; // Update last position
      console.log(`Updated lastPosition after branch to ${lastPosition}`);
      console.log(`Processing branch at  branch level ${branch.level}`);

    }
  });

  // Ensure rung segment is added above the horizontal branch line if it has been replaced by an element
  elements.forEach(element => {
    const branchPosition = element.position;
    const branchEndPosition = branchPosition + segmentWidth;
    if (element.type !== 'branch_vertical' && element.type !== 'branch_horizontal' && element.y1 !== 80) {
      if (!segments.some(seg => seg.x1 === branchPosition && seg.y1 === 80) && 
          !elements.some(el => el.position === branchPosition && el.y1 === 80 && el.type !== 'branch_vertical')) {
        segments.push({
          id: `segment-${branchPosition}-${80}`,
          x1: branchPosition,
          x2: branchEndPosition,
          y1: 80,
          y2: 80,
          state: 1, // Default state
          type: 'segment',
          level: 0,
          isLast: false // Not the last segment
        });
        processedPositions.add(branchPosition);
        lastPosition = branchEndPosition; // Update last position
        console.log(`segment positioned at ${branchPosition}`);
      }
    } else if (element.type !== 'branch_vertical' && element.type !== 'branch_horizontal' && element.y1 === 80) {
      // If there's an element at y1 = 80, remove the segment to avoid overlap
      segments = segments.filter(seg => {
        if (seg.x1 === element.position && seg.y1 === 80) {
          console.log(`Removing segment with x1: ${seg.x1}, y1: ${seg.y1}`);
          return false; // This will remove the segment
        }
        return true;
      });
      processedPositions.add(element.position); // Update processed positions
      lastPosition = Math.max(lastPosition, element.position + segmentWidth); // Update last position
      console.log(`Removed overlapping segment at ${element.position}`);
    }
  });

  console.log('Segments before filtering:', JSON.parse(JSON.stringify(segments)));

  // Add remaining segments after the last element or branch
  for (let x = lastPosition; x < rungWidth; x += segmentWidth) {
    if (!processedPositions.has(x)) {
      segments.push({
        id: `segment-${x}-${80}`,
        x1: x,
        x2: Math.min(x + segmentWidth, rungWidth),
        y1: 80,
        y2: 80,
        state: 1, // Default state
        type: 'segment',
        level: 0,
        isLast: x + segmentWidth >= rungWidth // Mark the last segment
      });
      processedPositions.add(x);
    }
  }

  console.log('Segments before final filtering:', JSON.parse(JSON.stringify(segments)));

  // Filter out segments that overlap with elements, but keep the segment where the branch was added
  const nonOverlappingSegments = segments.filter(segment => {
    return !nonBranchElements.some(element =>
      (segment.x1 < element.position + segmentWidth) && (segment.x2 > element.position)
    ) || segment.id.startsWith('branch-horizontal') || segment.id.startsWith('segment');
  });

  console.log('Final segments after filtering:', JSON.parse(JSON.stringify(nonOverlappingSegments)));
  return nonOverlappingSegments;
}








function getRungWidth(elements, isEndLine = false) {
  // Ensure elements is an array before filtering
  const elementsArray = Array.isArray(elements) ? elements : [];

  // Filter elements that are positioned on the main rung line (y1 === 80)
  const filteredElements = elementsArray.filter(el => el.y1 === 80);

  // Calculate the base width based on the filtered elements
  const baseWidth = filteredElements.length * 72; // Each element contributes 72 to the width
  const totalWidth = baseWidth + (isEndLine ? 30 : 0);

  return Math.max(totalWidth, ladderLogicWidth.value); // Use the greater of calculated width or the container width
}




    // Methods to add rung branches

    const showPlusButton = ref(false);
    const plusButtonPosition = ref({ x: 0, y: 0 });
    const selectedBranchElement = ref(null); // To track the selected element for branching
    const showExtendBranchButton = ref(false);
    const extendBranchButtonPosition = ref({ x: 0, y: 0 });

    const computedVerticalBranches = computed(() => {
      return rungs.value.map((rung) => {
        return rung.elements.filter((element) => element.type === 'branch_vertical');
      });
    });

    const computedHorizontalBranches = computed(() => {
      return rungs.value.map((rung) => {
        return rung.elements.filter((element) => element.type === 'branch_horizontal');
      });
    });


    function addBranch() {
  const rungIndex = selectedRung.value;
  const rung = rungs.value[rungIndex];
  let position = null;

  console.log('Current rung index:', rungIndex);
  console.log('Selected segment:', selectedSegment.value);
  console.log('Selected element:', selectedElement.value);

  // Determine the selected position
  if (selectedSegment.value !== null) {
    const segment = rung.segments.find(seg => seg.id === selectedSegment.value);
    console.log('Found segment for selectedSegment:', segment);

    if (segment) {
      position = segment.x1;
    }
  } else if (selectedElement.value !== null) {
    const element = rung.elements.find(el => el.id === selectedElement.value);
    console.log('Found element:', element);

    if (element) {
      position = element.position;
    }
  }

  if (position === null) {
    console.error('No position found for adding branch.');
    return;
  }

  // Find all branch levels at the selected position, considering both segments and elements
  const branchesAtPosition = rung.elements.filter(el => 
    el.position === position && 
    (el.type === 'branch_vertical' || el.type === 'branch_horizontal' || (el.type !== 'branch_vertical' && el.type !== 'branch_horizontal'))
  );

  let level = 1;

  if (branchesAtPosition.length > 0) {
    // Determine the appropriate level for the new branch
    const maxLevelAtPosition = Math.max(...branchesAtPosition.map(b => b.level || 1));

    // Check if the position is part of an extended branch
    const extendedBranchSegment = rung.elements.find(el => 
      el.position === position && 
      el.type === 'branch_horizontal' && 
      el.level === maxLevelAtPosition
    );

    if (extendedBranchSegment) {
      // If extending a branch at this position, add the new branch at the next level
      level = maxLevelAtPosition + 1;
    } else {
      // Otherwise, maintain the level and add the branch at the same level
      level = maxLevelAtPosition;
    }
  }

  // Double-check to ensure we have the correct branch level
  console.log('Determined branch level:', level);

  const yOffset = level * 70; // Calculate y offset based on the branch level

  // Add vertical line at the start of the branch
  const verticalStart = {
    id: `branch-vertical-start-${position}-${80}-${level}`,
    type: 'branch_vertical',
    position: position,
    y1: 80 + (level - 1) * 70, // Adjust y1 to start from the previous branch level
    y2: 80 + yOffset, // Adjust y2 based on level
    state: 1,
    level: level
  };
  rung.elements.push(verticalStart);

  // Add horizontal branch segment
  const horizontalBranch = {
    id: `branch-horizontal-${position}-${level}`,
    type: 'branch_horizontal',
    position: position,
    y1: 78 + yOffset, // Adjust y1 based on level
    y2: 78 + yOffset, // Same y for both points in a horizontal line
    state: 1,
    level: level
  };
  rung.elements.push(horizontalBranch);

  // Add vertical line at the end of the branch
  const verticalEnd = {
    id: `branch-vertical-end-${position + 72}-${level}`,
    type: 'branch_vertical',
    position: position + 72,
    y1: 80 + yOffset, // Adjust y1 based on level
    y2: 80 + (level - 1) * 70, // Adjust y2 to end at the previous branch level
    state: 1,
    level: level
  };
  rung.elements.push(verticalEnd);

  // Initialize currentElement and currentRungIndex
  currentElement.value = { id: horizontalBranch.id, position: horizontalBranch.position, type: horizontalBranch.type, level: horizontalBranch.level };
  currentRungIndex.value = rungIndex;

  // Deselect the current segment or element and select the horizontal branch line
  selectedSegment.value = null;
  selectedElement.value = horizontalBranch.id;
  selectedRung.value = rungIndex;

  // Update the position of the plus button
  plusButtonPosition.value = {
    top: horizontalBranch.y1 - 22, // Position the button 25px below the horizontal branch
    left: horizontalBranch.position + 51  // Center the button, assuming the button width is 24px and element width is 72px
  };
  showPlusButton.value = true;

  updateSegments();

  // Push the new state to history
  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  console.log('Branch added at position:', position, 'Level:', level, 'Existing branches at position:', branchesAtPosition);
}




function extendBranch() {
  const rung = rungs.value[currentRungIndex.value];
  const branchElement = currentElement.value;

  if (!branchElement) return;

  const branchLevel = branchElement.level;
  const newPosition = branchElement.position + 72; // The position to extend to
  console.log(`Attempting to extend branch at level ${branchLevel} from position ${branchElement.position} to ${newPosition}`);

  // Remove the existing vertical end segments at the new position for this branch level
  rung.elements = rung.elements.filter(el => {
    const shouldRemove = (el.type === 'branch_vertical' && el.position === newPosition && el.level === branchLevel);
    if (shouldRemove) {
      console.log(`Removing vertical end segment: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
    } else {
      console.log(`Keeping element: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
    }
    return !shouldRemove;
  });

  // Add the new horizontal and vertical segments for the extended branch, ensuring no duplicates
  const existingHorizontalSegment = rung.elements.find(el =>
    el.type === 'branch_horizontal' && el.position === newPosition && el.level === branchLevel
  );

  const existingVerticalEndSegment = rung.elements.find(el =>
    el.type === 'branch_vertical' && el.position === newPosition + 72 && el.level === branchLevel
  );

  if (!existingHorizontalSegment) {
    rung.elements.push({
      id: `branch-horizontal-${newPosition}-${branchLevel}`,
      type: 'branch_horizontal',
      position: newPosition,
      y1: 78 + branchLevel * 70, // Adjust y1 based on branch level
      y2: 78 + branchLevel * 70, // Same y for both points in a horizontal line
      state: 1,
      level: branchLevel
    });
  } else {
    console.log(`Horizontal segment at position ${newPosition} already exists.`);
  }

  if (!existingVerticalEndSegment) {
    rung.elements.push({
      id: `branch-vertical-end-${newPosition + 72}-${branchLevel}`,
      type: 'branch_vertical',
      position: newPosition + 72,
      y1: 80 + branchLevel * 70, // Adjust y1 based on branch level
      y2: 80,
      state: 1,
      level: branchLevel
    });
  } else {
    console.log(`Vertical end segment at position ${newPosition + 72} already exists.`);
  }

  // Extend any lower-level branches that intersect with this new segment
  const lowerBranches = rung.elements.filter(el => 
    (el.type === 'branch_horizontal' || (el.type !== 'branch_vertical' && el.level < branchLevel)) 
    && el.position === branchElement.position
  );

  lowerBranches.forEach(lowerBranch => {
    const lowerBranchNewPosition = lowerBranch.position + 72;
    console.log(`Extending lower branch at level ${lowerBranch.level} from position ${lowerBranch.position} to ${lowerBranchNewPosition}`);

    rung.elements = rung.elements.filter(el => {
      const shouldRemove = (el.type === 'branch_vertical' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level);
      if (shouldRemove) {
        console.log(`Removing overlapping vertical segment for lower branch: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
      } else {
        console.log(`Keeping element: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
      }
      return !shouldRemove;
    });

    // Check if there's a non-branch element or an existing horizontal segment in this position
    const existingElementAtNewPosition = rung.elements.find(el =>
      (el.type !== 'branch_vertical' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level) ||
      (el.type === 'branch_horizontal' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level)
    );

    if (!existingElementAtNewPosition) {
      rung.elements.push({
        id: `branch-horizontal-${lowerBranchNewPosition}-${lowerBranch.level}`,
        type: 'branch_horizontal',
        position: lowerBranchNewPosition,
        y1: 78 + lowerBranch.level * 70, // Adjust y1 based on lower branch level
        y2: 78 + lowerBranch.level * 70, // Same y for both points in a horizontal line
        state: 1,
        level: lowerBranch.level
      });
    } else {
      console.log(`An element already exists at position ${lowerBranchNewPosition} for level ${lowerBranch.level}.`);
    }

    const existingLowerVerticalEndSegment = rung.elements.find(el =>
      el.type === 'branch_vertical' && el.position === lowerBranchNewPosition + 72 && el.level === lowerBranch.level
    );

    if (!existingLowerVerticalEndSegment) {
      rung.elements.push({
        id: `branch-vertical-end-${lowerBranchNewPosition + 72}-${lowerBranch.level}`,
        type: 'branch_vertical',
        position: lowerBranchNewPosition + 72,
        y1: 80 + lowerBranch.level * 70, // Adjust y1 based on lower branch level
        y2: 80,
        state: 1,
        level: lowerBranch.level
      });
    } else {
      console.log(`Vertical end segment at position ${lowerBranchNewPosition + 72} already exists.`);
    }
  });

  // Update the UI
  updateSegments();

  // Recalculate and show the extend button if needed
  if (currentElement.value && currentElement.value.level === branchLevel) {
    extendBranchButtonPosition.value = {
      top: branchElement.y1 - 30,
      left: newPosition + 100
    };
    showExtendBranchButton.value = true;
  }

  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  // Clear selection after extending
  selectedSegment.value = null;
  selectedRung.value = null;
  selectedElement.value = null;

  console.log(`Branch successfully extended from position ${branchElement.position} to ${newPosition}`);
}































function getElementPosition(element, rungIndex) {
  const rung = computedSegments.value[rungIndex];
  if (!rung) return { x: 0, y: 0 };

  const segment = rung.segments.find(seg => seg.x1 === element.position);
  return { x: segment ? segment.x1 : 0, y: rungIndex * 100 }; // Adjust y-coordinate based on rung index
}

function getSegmentPosition(segment) {
  return { x: segment.x1, y: segment.y1 }; // Adjust y1 based on your segment structure
}

function deleteSelectedBranch(position, rungIndex) {
  const rung = rungs.value[rungIndex];

  // Log the current rung state for debugging
  console.log('Current rung state:', JSON.stringify(rung.elements, null, 2));

  // Find the selected horizontal branch element at the highest level
  const selectedBranchElement = rung.elements
    .filter(el => el.type === 'branch_horizontal' && el.position === position)
    .sort((a, b) => b.level - a.level)[0];  // Select the highest-level branch

  if (!selectedBranchElement) {
    console.error('Branch element not found at position:', position);
    return;
  }

  console.log(`Selected branch element: ${JSON.stringify(selectedBranchElement)}`);

  // Find the branch-vertical-start to the left of the selected branch element at the same level
  const branchStartElement = rung.elements
    .filter(el => el.type === 'branch_vertical' && el.id.includes('start') && el.level === selectedBranchElement.level)
    .reverse()
    .find(el => el.position <= selectedBranchElement.position);

  if (!branchStartElement) {
    console.error('Branch start element not found.');
    return;
  }

  console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);

  // Find the branch-vertical-end to the right of the selected branch element at the same level
  const branchEndElement = rung.elements.find(el => 
    el.type === 'branch_vertical' && 
    el.id.includes('end') && 
    el.position > selectedBranchElement.position && 
    el.level === selectedBranchElement.level
  );

  if (!branchEndElement) {
    console.error('Branch end element not found.');
    return;
  }

  console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);

  // Calculate the range of positions to delete
  const startPosition = branchStartElement.position;
  const endPosition = branchEndElement.position;

  console.log(`Deleting branch elements from position ${startPosition} to ${endPosition} at level ${selectedBranchElement.level}`);

  // Remove all elements within the range between startPosition and endPosition that are at the same level
  rung.elements = rung.elements.filter(el => {
    const isInRange = el.position >= startPosition && el.position <= endPosition;
    const isBranchElement = el.type.startsWith('branch_');
    const isSameLevel = el.level === selectedBranchElement.level;
    // Check if the element is at the same level and within the range
    return !(isInRange && isBranchElement && isSameLevel);
  });

  updateSegments();

  // Push the new state to history
  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  console.log(`Branch at level ${selectedBranchElement.level} deleted from position ${startPosition} to ${endPosition}`);

  selectedSegment.value = null;
  selectedRung.value = null;
  showPlusButton.value = false;
  showExtendBranchButton.value = false;
  showDeleteBranchButton.value = false;
}















const nonBranchSegments = computed(() => {
  return rungs.value.map((rung) => {
    return rung.segments.filter(segment => !isBranchSegment(segment));
  });
});

function isBranchSegment(segment) {
  const branchTypes = ['branch_vertical', 'branch_horizontal'];
  return branchTypes.some(type => segment.id.includes(type));
}
const nonBranchElements = computed(() => {
  return rungs.value.map((rung) => {
    return rung.elements.filter(element => !element.type.startsWith('branch_'));
  });
});

    const currentElement = ref(null); // To hold the current element
    const currentRungIndex = ref(null); // To hold the current rung index

    watch(() => currentElement.value, (newVal) => {
  console.log('currentElement changed:', newVal);
});

watch(() => currentRungIndex.value, (newVal) => {
  console.log('currentRungIndex changed:', newVal);
});

    const deleteButtonCondition = computed(() => {
  if (!currentElement.value || currentRungIndex.value === null) return false;

  const isHorizontalBranch = currentElement.value.id.startsWith('branch-horizontal');
  const conditionMet = (selectedSegment.value === currentElement.value.id && selectedRung.value === currentRungIndex.value && !isSimulating.value && isHorizontalBranch);
  console.log(`deleteButtonCondition - selectedSegment: ${selectedSegment.value}, element.id: ${currentElement.value.id}, selectedRung: ${selectedRung.value}, rungIndex: ${currentRungIndex.value}, isSimulating: ${isSimulating.value}, isHorizontalBranch: ${isHorizontalBranch}, conditionMet: ${conditionMet}`);
  return conditionMet;
});

const deleteButtonPosition = reactive({ top: 0, left: 0 });




    //+++++++++++++here is script related to add tools to rungs ++++++++++++++
    const tools = reactive([
      {
        id: 'coil',
        label: 'Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'r_coil',
        label: 'R_Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text></svg>',
        visible: false,
      },
      {
        id: 'contact_no',
        label: 'N.O. Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="28" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="18" x2="28" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="18" x2="44" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'contact_nc',
        label: 'N.C. Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="28" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="18" x2="28" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="18" x2="44" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="22" x2="44" y2="50" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'contact_pos',
        label: 'P Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="26" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="26" y1="18" x2="26" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="18" x2="46" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text></svg>',
        visible: false,
      },
      {
        id: 'contact_neg',
        label: 'N Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="26" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="26" y1="18" x2="26" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="18" x2="46" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text></svg>',
        visible: false,
      },
      {
        id: 'coil_set',
        label: 'Set Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">S</text></svg>',
        visible: false,
      },
      {
        id: 'coil_reset',
        label: 'Reset Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text></svg>',
        visible: false,
      },
      {
        id: 'timer_ton',
        label: 'TON Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TON</text></svg>',
        visible: false,
      },
      {
        id: 'timer_tof',
        label: 'TOF Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TOF</text></svg>',
        visible: false,
      },
      {
        id: 'timer_tonr',
        label: 'TONR Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TONR</text></svg>',
        visible: false,
      },
      {
        id: 'counter_ctu',
        label: 'CTU Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T U</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'counter_ctd',
        label: 'CTD Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T D</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'counter_ctud',
        label: 'CTUD Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">CTUD</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="25" y1="45" x2="25" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="47" y1="45" x2="47" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_equ',
        label: 'EQU',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">E Q U</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_neq',
        label: 'NEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_grt',
        label: 'GRT',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G R T</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_geq',
        label: 'GEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_les',
        label: 'LES',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E S</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_leq',
        label: 'LEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'add',
        label: 'ADD',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">ADD</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">+</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'sub',
        label: 'SUB',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">SUB</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">-</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'mul',
        label: 'MUL',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">MUL</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">x</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'div',
        label: 'DIV',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">DIV</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">/</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'logical_and',
        label: 'AND',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">AND</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'logical_or',
        label: 'OR',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">OR</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'logical_not',
        label: 'NOT',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">NOT</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },

    ]);

    const draggedTool = ref(null); // To store the currently dragged tool
    const addToolDialog = ref({ visible: false });
    const addRungDialog = ref({ visible: false });


    // Define a counter object for unique identifiers
    const elementCounters = reactive({
      coil: 0,
      r_coil: 0,
      contact_no: 0,
      contact_nc: 0,
      contact_pos: 0,
      contact_neg: 0,
      coil_set: 0,
      coil_reset: 0,
      timer_ton: 0,
      timer_tof: 0,
      timer_tonr: 0,
      counter_ctu: 0,
      counter_ctd: 0,
      counter_ctud: 0,
      comparator_equ: 0,
      comparator_neq: 0,
      comparator_grt: 0,
      comparator_geq: 0,
      comparator_les: 0,
      comparator_leq: 0,
      add: 0,
      sub: 0,
      mul: 0,
      div: 0,
      logical_and: 0,
      logical_or: 0,
      logical_not: 0,
    });

    const history = reactive({
      states: [],
      currentIndex: -1,
    });

    function addToolToRung(toolType) {
  if (isSimulating.value) {
    addToolDialog.value.visible = true; // Show dialog
    return;
  }

  const tool = tools.find(t => t.id === toolType);
  if (!tool) {
    console.error('Tool not found:', toolType);
    return;
  }
  const newId = generateId(toolType);

  // Ensure a rung is selected
  if (selectedRung.value === null || selectedRung.value === undefined) {
    selectedRung.value = 0; // Default to the first rung
  }

  const rung = rungs.value[selectedRung.value];
  if (!rung) {
    console.error('Invalid rung index:', selectedRung.value);
    return;
  }

  let newPosition = 0;
  if (selectedSegment.value !== null) {
    const segment = rung.segments.find(seg => seg.id === selectedSegment.value);
    if (segment) {
      // Replace the selected segment with a new element
      replaceSegmentWithElement(rung, segment, toolType, newId);
      selectedSegment.value = null; // Reset after adding
      showPlusButton.value = false; // Hide the plus button
      showExtendBranchButton.value = false;
    }
  } else {
    // Calculate the first available position that does not overlap any existing elements
    const nonOverlappingSegment = rung.segments.find(seg => !isSegmentOverlappingTool(seg));
    if (nonOverlappingSegment) {
      newPosition = nonOverlappingSegment.x1;
      replaceSegmentWithElement(rung, nonOverlappingSegment, toolType, newId);
    } else if (rung.elements.length > 0) {
      const lastElement = rung.elements[rung.elements.length - 1];
      if (lastElement.type !== 'branch_horizontal') { // Ignore horizontal branches
        newPosition = lastElement.position + 72; // Assuming each element is 72 units wide
      }
      rung.elements.push({
        id: newId,
        position: newPosition,
        type: toolType,
        state: toolType === 'contact_no' ? 0 : 
               (toolType === 'contact_nc' ? 1 : 
               (toolType === 'contact_pos' ? 0 : 
               (toolType === 'contact_neg' ? 1 : 
               (toolType === 'coil' ? 0 : 
               (toolType === 'coil_set' ? 0 : 
               (toolType === 'coil_reset' ? 0 : 0)))))),
        presetTime: toolType.startsWith('timer') ? 5 : undefined,
        elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
        linkedId: toolType.startsWith('timer') ? null : undefined,
        previousState: toolType.startsWith('counter') ? 0 : undefined, // Initialize previousState for counters
        currentValue: toolType.startsWith('counter') ? (toolType === 'counter_ctd' ? tool.presetValue : 0) : undefined // Initialize currentValue for counters
      });
    } else {
      // If no elements exist, add the element at the beginning
      rung.elements.push({
        id: newId,
        position: newPosition, // Will be 0 if it's the first element
        type: toolType,
        state: toolType === 'contact_no' ? 0 : 
               (toolType === 'contact_nc' ? 1 : 
               (toolType === 'contact_pos' ? 0 : 
               (toolType === 'contact_neg' ? 1 : 
               (toolType === 'coil' ? 0 : 
               (toolType === 'coil_set' ? 0 : 
               (toolType === 'coil_reset' ? 0 : 0)))))),
        presetTime: toolType.startsWith('timer') ? 5 : undefined,
        elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
        linkedId: toolType.startsWith('timer') ? null : undefined,
        previousState: toolType.startsWith('counter') ? 0 : undefined, // Initialize previousState for counters
        currentValue: toolType.startsWith('counter') ? (toolType === 'counter_ctd' ? tool.presetValue : 0) : undefined // Initialize currentValue for counters
      });
    }
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  if (toolType === 'timer_tonr') {
    addNewRungWithResetCoil(newId);
  } else if (toolType === 'coil_set') {
    addNewRungWithCoilReset(newId);
  } else if (toolType === 'coil_reset') {
    addNewRungWithCoilSet(newId);
  } else if (toolType === 'counter_ctu' || toolType === 'counter_ctd') {
    addNewRungWithCounterReset(newId);
  } else {
    // Push the new state to history for other tools
    pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
  }
}





function addNewRungWithCounterReset(counterId) {
  const newRungId = rungs.value.length + 1;
  const newCoilId = generateId('r_coil');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];

  // Calculate appropriate y1 and y2 values for the r_coil
  const y1 = lastSegment.y1;
  const y2 = lastSegment.y2;

  // Add the r_coil element to the new rung
  newRung.elements.push({
    id: newCoilId,
    position: lastSegment.x1,
    type: 'r_coil',
    state: 0,
    linkedId: counterId,
    y1: y1,
    y2: y2
  });

  // Link the reset coil to the counter
  const counterElement = findElementById(counterId);
  if (counterElement) {
    counterElement.resetCoilId = newCoilId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newCoilId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}


function addNewRungWithResetCoil(timerId) {
  const newRungId = rungs.value.length + 1;
  const newCoilId = generateId('r_coil');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];

  // Calculate appropriate y1 and y2 values for the coil
  const y1 = lastSegment.y1;
  const y2 = lastSegment.y2;

  // Add the coil element to the new rung
  newRung.elements.push({
    id: newCoilId,
    position: lastSegment.x1,
    type: 'r_coil',
    state: 0,
    linkedId: timerId,
    y1: y1,
    y2: y2
  });

  // Link the reset coil to the TONR timer
  const timerElement = findElementById(timerId);
  if (timerElement) {
    timerElement.resetCoilId = newCoilId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newCoilId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}

function addNewRungWithCoilReset(linkedId) {
  const newRungId = rungs.value.length + 1;
  const newId = generateId('coil_reset');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];

  // Calculate appropriate y1 and y2 values for the coil_reset
  const y1 = lastSegment.y1;
  const y2 = lastSegment.y2;

  // Add the coil_reset element to the new rung
  newRung.elements.push({
    id: newId,
    position: lastSegment.x1,
    type: 'coil_reset',
    state: 0,
    linkedId: linkedId,
    y1: y1,
    y2: y2
  });

  // Link the coil_set and coil_reset
  const coilSetElement = findElementById(linkedId);
  if (coilSetElement) {
    coilSetElement.linkedId = newId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}


function addNewRungWithCoilSet(linkedId) {
  const newRungId = rungs.value.length + 1;
  const newId = generateId('coil_set');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];

  // Calculate appropriate y1 and y2 values for the coil_set
  const y1 = lastSegment.y1;
  const y2 = lastSegment.y2;

  // Add the coil_set element to the new rung
  newRung.elements.push({
    id: newId,
    position: lastSegment.x1,
    type: 'coil_set',
    state: 0,
    linkedId: linkedId,
    y1: y1,
    y2: y2
  });

  // Link the coil_set and coil_reset
  const coilResetElement = findElementById(linkedId);
  if (coilResetElement) {
    coilResetElement.linkedId = newId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}


function findElementById(id) {
  for (const rung of rungs.value) {
    const element = rung.elements.find(el => el.id === id);
    if (element) {
      return element;
    }
  }
  return null;
}

function replaceSegmentWithElement(rung, segment, toolType, newId) {
  console.log(`Replacing segment with element. Segment ID: ${segment.id}, x1: ${segment.x1}, x2: ${segment.x2}, y1: ${segment.y1}`);

  // Log elements before filtering
  console.log('Elements before filtering:', JSON.parse(JSON.stringify(rung.elements)));

  // Find the level of the branch (if applicable) based on the y1 position of the segment
  let branchLevel = segment.y1 !== 80 ? (segment.y1 - 78) / 70 : 0;

  console.log(`Branch level determined: ${branchLevel}`);

  // Remove any elements currently in the segment (if any), except branch elements
  rung.elements = rung.elements.filter(el => 
    (el.position !== segment.x1 || el.y1 !== segment.y1) || 
    (el.type === 'branch_vertical') ||
    (el.type === 'branch_horizontal' && el.y1 !== segment.y1)  // Keep horizontal branches not at the same y position
  );

  // Log elements after filtering
  console.log('RSE-Elements after filtering:', JSON.parse(JSON.stringify(rung.elements)));

  // Add the new element exactly at the start of the segment
  rung.elements.push({
    id: newId,
    position: segment.x1,
    type: toolType,
    y1: segment.y1, // Use the y1 from the segment being replaced
    y2: segment.y2, // Use the y2 from the segment being replaced
    state: toolType === 'contact_no' ? 0 :
           (toolType === 'contact_nc' ? 1 : 
           (toolType === 'contact_pos' ? 0 : 
           (toolType === 'contact_neg' ? 1 : 
           (toolType === 'coil' ? 0 : 0)))), // Set initial state for NO contact to 0, NC contact to 1, POS contact to 0, NEG contact to 1, coil to 0
    presetTime: toolType.startsWith('timer') ? 5 : undefined,
    elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
    level: branchLevel // Assign the branch level to the new element
  });

  console.log(`New element added with level: ${branchLevel}`);

  // Recalculate segments after adding the new element
  updateSegments();
}




    function generateId(type) {
      const prefixes = {
        coil: 'C',
        r_coil: 'R_C',
        contact_no: 'NO',
        contact_nc: 'NC',
        contact_pos: 'P',
        contact_neg: 'N',
        coil_set: 'SC',
        coil_reset: 'RC',
        timer_ton: 'TON',
        timer_tof: 'TOF',
        timer_tonr: 'TONR',
        counter_ctu: 'CTU',
        counter_ctd: 'CTD',
        counter_ctud: 'CTUD',
        comparator_equ: 'EQU',
        comparator_neq: 'NEQ',
        comparator_grt: 'GRT',
        comparator_geq: 'GEQ',
        comparator_les: 'LES',
        comparator_leq: 'LEQ',
        add: 'ADD',
        sub: 'SUB',
        mul: 'MUL',
        div: 'DIV',
        logical_and: 'AND',
        logical_or: 'OR',
        logical_not: 'NOT',
      };

      // Increment the counter for this type
      elementCounters[type] += 1;

      // Generate the ID based on the type and current counter value
      return `${prefixes[type]}${elementCounters[type]}`;
    }

    function getPositionX(element) {
      return element.position !== undefined ? element.position : 0;
    }


    function deleteElement(elementId, rungIndex) {
  const rung = rungs.value[rungIndex];
  const element = rung.elements.find(el => el.id === elementId);

  if (element) {
    console.log(`Deleting element with ID: ${elementId}`);
    console.log(`Element details: ${JSON.stringify(element)}`);

    // Check if the element is part of a branch
    if (element.type.startsWith('branch_')) {
      deleteSelectedBranch(element.position, rungIndex);
    } else {
      // Regular element deletion logic
      rung.elements = rung.elements.filter(el => el.id !== elementId);

      // Check if the deleted element was part of a branch
      const branchPosition = element.position;
      const branchLevel = element.level; // Get the branch level of the deleted element

      console.log(`Branch position: ${branchPosition}, Branch level: ${branchLevel}`);

      // Adjust the condition to handle branch vertical elements in level 1 correctly
      if (branchLevel !== undefined && (element.y1 !== 80 || element.type === 'branch_vertical')) { 
        console.log('Element is part of a branch.');

        // Find the branch-vertical-start to the left of the deleted element
        const branchStartElement = rung.elements
          .filter(el => el.type === 'branch_vertical' && el.id.includes('start') && el.level === branchLevel)
          .reverse()
          .find(el => el.position <= branchPosition);

        // Find the branch-vertical-end to the right of the deleted element
        const branchEndElement = rung.elements.find(el => 
          el.type === 'branch_vertical' && el.id.includes('end') && el.position > branchPosition && el.level === branchLevel
        );

        if (!branchStartElement || !branchEndElement) {
          console.error('Branch start or end element not found.');
          console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);
          console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);
          return;
        }

        console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);
        console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);

        const startPosition = branchStartElement.position;
        const endPosition = branchEndElement.position;

        // Replace the deleted element with the appropriate branch segments
        for (let x = startPosition; x < endPosition; x += 72) {
          // Check if there's already an element at this position
          const existingElement = rung.elements.find(el => el.position === x && el.y1 === (78 + branchLevel * 70));

          if (!existingElement) {
            // Add the horizontal segment if there's no element in the branch
            rung.elements.push({
              id: `branch-horizontal-${x}-${78 + branchLevel * 70}`,
              type: 'branch_horizontal',
              position: x,
              y1: 78 + branchLevel * 70, // Adjust y1 based on level
              y2: 78 + branchLevel * 70, // Same y for both points in a horizontal line
              state: 1,
              level: branchLevel
            });
          }
        }

        console.log(`Recreated branch elements between positions ${startPosition} and ${endPosition} at level ${branchLevel}`);
      }

      // Handle the case where the element is part of a regular rung (non-branch)
      if (branchLevel === undefined) {
        const segmentPosition = element.position;
        console.log(`Deleted a regular element at position ${segmentPosition}. Replacing with a regular rung segment.`);

        // Replace the deleted element with a regular rung segment if needed
        rung.elements.push({
          id: `rung-segment-${segmentPosition}`,
          type: 'rung_segment',
          position: segmentPosition,
          y1: 80, // Regular rung height
          y2: 80, // Same y for both points in a rung
          state: 1
        });
      }

      updateSegments();

      // Push the new state to history
      pushState({
        rungs: JSON.parse(JSON.stringify(rungs.value)),
        variables: JSON.parse(JSON.stringify(variables.value)),
      });

      // Hide the plus button after deletion
      showPlusButton.value = false;
      showExtendBranchButton.value = false;

      console.log('Element deleted:', elementId);
    }
  } else {
    console.log('No element found for deletion:', elementId);
  }
}







const showDeleteBranchButton = ref(false);

function selectSegment(index, rungIndex) {
  if (isSimulating.value) {
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    showDeleteBranchButton.value = false;
    return;
  }

  const rung = computedSegments.value[rungIndex];
  const segment = rung?.segments?.find((seg) => seg.id === index);

  console.log(`Selecting segment with ID: ${index} in rung ${rungIndex}`);

  if (segment && !segment.isLast) {
    if (selectedSegment.value === index && selectedRung.value === rungIndex) {
      selectedSegment.value = null;
      selectedRung.value = null;
      selectedElement.value = null;
      showPlusButton.value = false;
      showExtendBranchButton.value = false;
      showDeleteBranchButton.value = false;
    } else {
      selectedSegment.value = index;
      selectedRung.value = rungIndex;
      selectedElement.value = null;

      const branchStartElement = rung.elements
        .filter(el => el.type === 'branch_vertical' && el.id.includes('start'))
        .reverse()
        .find(el => el.position <= segment.x1);

      const branchVerticalEnd = rung.elements.find(el => 
        el.type === 'branch_vertical' && 
        el.id.includes('end') && 
        el.position > branchStartElement?.position &&
        el.level === branchStartElement?.level
      );

      const shouldShowExtendBranchButton = branchVerticalEnd && segment.x2 === branchVerticalEnd.position;

      const horizontalBranch = rung.elements.find(
        el => el.type === 'branch_horizontal' && el.position === segment.x1 && el.level === branchStartElement?.level
      );

      if (horizontalBranch) {
        const highestLevelBranch = Math.max(
          ...rung.elements
            .filter(el => el.position === segment.x1 && el.type === 'branch_horizontal')
            .map(el => el.level)
        );

        const isHighestLevelBranch = horizontalBranch.level === highestLevelBranch;

        console.log(`Selected branch level: ${horizontalBranch.level}`);

        if (shouldShowExtendBranchButton) {
          plusButtonPosition.value = {
            top: horizontalBranch.y1 - 22,
            left: horizontalBranch.position + 51
          };
          extendBranchButtonPosition.value = {
            top: horizontalBranch.y1 - 30,
            left: horizontalBranch.position + 100
          };
          showExtendBranchButton.value = true;

          if (isHighestLevelBranch) {
            deleteButtonPosition.top = horizontalBranch.y1 - 50;
            deleteButtonPosition.left = horizontalBranch.position + 65;

            currentElement.value = { 
              id: horizontalBranch.id, 
              position: horizontalBranch.position, 
              type: horizontalBranch.type, 
              level: horizontalBranch.level 
            };
            currentRungIndex.value = rungIndex;
          }

          showDeleteBranchButton.value = isHighestLevelBranch;
        } else {
          plusButtonPosition.value = {
            top: horizontalBranch.y1 - 22, // Adjusted to position the button correctly relative to the branch level
            left: segment.x1 + 15 + (segment.x2 - segment.x1) / 2
          };

          currentElement.value = null;
          currentRungIndex.value = null;
          showExtendBranchButton.value = false;
          showDeleteBranchButton.value = false;
        }

        showPlusButton.value = isHighestLevelBranch;
      } else {
        // Handle regular rung segments (level 0)
        // Check if there is a branch below this segment
        const isBranchBelow = rung.elements.some(el => 
          el.type === 'branch_horizontal' && 
          el.position >= segment.x1 && 
          el.position < segment.x2
        );

        if (!isBranchBelow) {
          plusButtonPosition.value = {
            top: 80 - 22,
            left: segment.x1 + 15 + (segment.x2 - segment.x1) / 2
          };

          showPlusButton.value = true;
        } else {
          showPlusButton.value = false;
        }

        currentElement.value = null;
        currentRungIndex.value = null;
        showExtendBranchButton.value = false;
        showDeleteBranchButton.value = false;
      }
    }

    console.log(`Segment selected at position x1: ${segment.x1}`);
  } else {
    console.log(`Selecting element with ID: ${index} in rung ${rungIndex}`);
    const horizontalBranch = rung.elements.find(
      el => el.type === 'branch_horizontal' && el.id === index
    );
    if (horizontalBranch) {
      selectedSegment.value = horizontalBranch.id;
      selectedElement.value = horizontalBranch.id;
      selectedRung.value = rungIndex;

      const branchStartElement = rung.elements
        .filter(el => el.type === 'branch_vertical' && el.id.includes('start'))
        .reverse()
        .find(el => el.position <= horizontalBranch.position);

      const branchVerticalEnd = rung.elements.find(el => 
        el.type === 'branch_vertical' && 
        el.id.includes('end') && 
        el.position > branchStartElement?.position &&
        el.level === branchStartElement?.level
      );

      const highestLevelBranch = Math.max(
        ...rung.elements
          .filter(el => el.position === horizontalBranch.position && el.type === 'branch_horizontal')
          .map(el => el.level)
      );

      const isHighestLevelBranch = horizontalBranch.level === highestLevelBranch;

      plusButtonPosition.value = {
        top: horizontalBranch.y1 - 22, // Adjusted to position the button correctly relative to the branch level
        left: horizontalBranch.position + 51
      };
      extendBranchButtonPosition.value = {
        top: horizontalBranch.y1 - 30,
        left: horizontalBranch.position + 100
      };
      showExtendBranchButton.value = branchVerticalEnd && horizontalBranch.position === branchVerticalEnd.position - 72;

      if (isHighestLevelBranch) {
        deleteButtonPosition.top = horizontalBranch.y1 - 50;
        deleteButtonPosition.left = horizontalBranch.position + 65;

        currentElement.value = { 
          id: horizontalBranch.id, 
          position: horizontalBranch.position, 
          type: horizontalBranch.type, 
          level: horizontalBranch.level 
        };
        currentRungIndex.value = rungIndex;
      }

      showPlusButton.value = isHighestLevelBranch;
      showDeleteBranchButton.value = isHighestLevelBranch;
    } else {
      console.log('Last segment selected, ignoring selection.');
    }
  }
}

function selectElement(element, rungIndex) {
  if (isSimulating.value) {
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    return;
  }

  if (element.type.startsWith('branch_vertical')) {
    // Do not allow selection of individual branch elements
    return;
  }

  console.log('Selecting element:', element.id);

  // Check if the selected element is the same as the currently selected one
  if (selectedElement.value === element.id && selectedRung.value === rungIndex) {
    selectedElement.value = null;
    selectedRung.value = null;
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
  } else {
    selectedElement.value = element.id;
    selectedRung.value = rungIndex;
    selectedSegment.value = null;

    // Identify the corresponding branch vertical end segment based on the branch level
    const rung = computedSegments.value[rungIndex];
    const branchStartElement = rung.elements
      .filter(el => el.type === 'branch_vertical' && el.id.includes('start'))
      .reverse()
      .find(el => el.position <= element.position);

    const branchVerticalEnd = rung.elements.find(el => 
      el.type === 'branch_vertical' && 
      el.id.includes('end') && 
      el.position > branchStartElement?.position &&
      el.level === branchStartElement?.level
    );

    const shouldShowExtendBranchButton = branchVerticalEnd && element.position === branchVerticalEnd.position - 72;

    // Check if the element is on the upper line of a branch
    const isUpperElement = element.y1 === 80;

    // Determine the highest level of any branch at this position
    const highestLevelBranch = Math.max(
      ...rung.elements
        .filter(el => el.position === element.position && el.type === 'branch_horizontal')
        .map(el => el.level)
    );

    const isHighestLevelBranch = element.level === highestLevelBranch;

    if (!isUpperElement && shouldShowExtendBranchButton && isHighestLevelBranch) {
      // Only show the branch-related buttons if the element is not an upperElement, is in the correct position, and is in the highest branch level
      plusButtonPosition.value = {
        top: element.y1 -5, // Position the button 25px below the element
        left: element.position + 51  // Center the button, assuming the button width is 24px and element width is 72px
      };
      extendBranchButtonPosition.value = {
        top: element.y1 -30, // Position the button at the same y as the element
        left: element.position + 100  // Position the button to the right of the rung assuming the rung is 72px
      };
      showExtendBranchButton.value = true;

      selectedSegment.value = element.id; // Use the element ID for selection

      // Ensure currentElement carries the level for the extendBranch function
      currentElement.value = { 
        id: element.id, 
        position: element.position, 
        type: element.type, 
        level: element.level 
      };
      currentRungIndex.value = rungIndex;

    } else {
      if (!isUpperElement) {
        // If not an upperElement, treat it normally
        plusButtonPosition.value = {
          top: element.y1 -5, // Adjust this value to position the button 25px below the element
          left: element.position + 51  // Center the button, assuming the button width is 24px and element width is 72px
        };
        extendBranchButtonPosition.value = {
          top: element.y1 - 30, // Position the button at the same y as the element
          left: element.position + 100  // Position the button to the right of the rung assuming the rung is 72px
        };
        showExtendBranchButton.value = false;
      } else {
        // For upperElements, only show the plus button
        plusButtonPosition.value = {
          top: 80 + 25, // Adjust this value to position the button 25px below the element
          left: element.position + 51  // Center the button, assuming the button width is 24px and element width is 72px
        };
        showExtendBranchButton.value = false;
      }
    }

    // Determine if the plus button should be shown
    const isBranchBelow = rung.elements.some(el => 
      el.type === 'branch_horizontal' && 
      el.position === element.position &&
      el.level > element.level
    );

    showPlusButton.value = isHighestLevelBranch && !isUpperElement && !isBranchBelow;
  }

  console.log('Selected element ID:', selectedElement.value);
}









    function startDrag(toolType) {
      draggedTool.value = toolType;
    }

    function handleDrop(event) {
      event.preventDefault();
      if (draggedTool.value) {
        addToolToRung(draggedTool.value);
        draggedTool.value = null;
      }
    }

    function dropOnSegment(segment, rungIndex) {
      if (draggedTool.value) {
        selectedSegment.value = segment.id;
        selectedRung.value = rungIndex;
        addToolToRung(draggedTool.value);
        draggedTool.value = null;
      }
    }

    function updateSegments() {
  rungs.value.forEach((rung, rungIndex) => {
    rung.segments = getLineSegments(rung.elements || []).map(segment => ({
      ...segment,
      state: 1, // Reset state to 1
    }));

    console.log(`Rung ${rungIndex} Segments:`);
    rung.segments.forEach(segment => {
      console.log(`US-Segment ${segment.id} - Position: (${segment.x1}, ${segment.x2}) - State: ${segment.state}`);
    });

    rung.elements.forEach(element => {
      console.log(`US-Element ${element.id} - Position: ${element.position} - State: ${element.state}`);
    });
  });

    // Ensure to re-calculate any additional logic after updating segments if needed
    calculateEnergizedStates();
}








    //+++++++++++++here is script related to tools functionality ++++++++++++++

    
    const timers = reactive({
      timer_ton: [],
      timer_tof: [],
    });
    const counters = reactive({
      counter_ctu: [],
      counter_ctd: [],
      counter_ctud: []
    });
    const availableContacts = computed(() => {
     return rungs.value.flatMap(rung =>
     rung.elements.filter(el => 
      ['contact_no', 'contact_nc', 'contact_pos', 'contact_neg'].includes(el.type)
        )
      );
    });

    const showTimerDialog = ref(false);
    const dialogPresetTime = ref(0);
    const timerElement = ref(null);
    const showCounterDialog = ref(false);
    const dialogPresetValue = ref(0);
    const dialogLinkedContact = ref(null);
    const counterElement = ref(null);


function initializeTimers() {
  // Initialize timers at the beginning of the simulation
  timers.timer_ton = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_ton'));
  timers.timer_tof = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_tof'));
  timers.timer_tonr = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_tonr'));
}
function initializeCounters() {
  counters.counter_ctu = rungs.value.flatMap(rung =>
    rung.elements
      .filter(el => el.type === 'counter_ctu')
      .map(el => ({ ...el, previousState: el.state }))
  );
  counters.counter_ctd = rungs.value.flatMap(rung =>
    rung.elements
      .filter(el => el.type === 'counter_ctd')
      .map(el => ({ ...el, previousState: el.state }))
  );

  counters.counter_ctu.forEach(counter => {
    console.log(`Initialize CTU: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);
  });

  counters.counter_ctd.forEach(counter => {
    console.log(`Initialize CTD: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);
  });
}



function configureTimer(element) {
  if (isSimulating.value) return; // Do not allow configuration during simulation

  timerElement.value = element;
  dialogPresetTime.value = element.presetTime || 0;
  dialogLinkedContact.value = element.linkedId || (availableContacts.value.length > 0 ? availableContacts.value[0].id : null);
  showTimerDialog.value = true;
}

function openTimerDialog(newId) {
  const timerElement = findElementById(newId);
  if (timerElement) {
    configureTimer(timerElement);
  }
}

function closeTimerDialog() {
  showTimerDialog.value = false;
}

function saveTimerSettings() {
  if (timerElement.value) {
    timerElement.value.presetTime = dialogPresetTime.value;
    timerElement.value.linkedId = dialogLinkedContact.value;
  }
  showTimerDialog.value = false;
}


    function configureCounter(element) {
      if (isSimulating.value) return; // Do not allow configuration during simulation

      counterElement.value = element;
      dialogPresetValue.value = element.presetValue || 0;
      dialogLinkedContact.value = element.linkedId || (availableContacts.value.length > 0 ? availableContacts.value[0].id : null);
      showCounterDialog.value = true;
    }

    function closeCounterDialog() {
      showCounterDialog.value = false;
    }

    function saveCounterSettings() {
      if (counterElement.value) {
        counterElement.value.presetValue = dialogPresetValue.value;
        counterElement.value.linkedId = dialogLinkedContact.value;
      }
      showCounterDialog.value = false;
    }
    function updateCounters() {
    counters.counter_ctu.forEach(counter => {
      console.log(`CTU Counter before update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}, previousState=${counter.previousState}`);
      if (counter.state === 1 && counter.previousState !== 1) {
        counter.currentValue = (counter.currentValue || 0) + 1;
        if (counter.currentValue >= counter.presetValue) {
          counter.currentValue = counter.presetValue; // Cap at preset value
          if (counter.state !== 2) {
            counter.state = 2;
            const linkedContact = findElementById(counter.linkedId);
            if (linkedContact && counter.previousState !== 2) { // Only toggle if previous state was not 2
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle the linked contact state
            }
          }
        }
      }
      counter.previousState = counter.state; // Update the previous state
      console.log(`CTU Counter after update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);

      const element = findElementById(counter.id);
      if (element) {
        element.currentValue = counter.currentValue;
        element.state = counter.state;
        element.previousState = counter.previousState;
      }
    });

    counters.counter_ctd.forEach(counter => {
      console.log(`CTD Counter before update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}, previousState=${counter.previousState}`);
      if (counter.state === 1 && counter.previousState !== 1) {
        if (counter.currentValue > 0) {
          counter.currentValue = (counter.currentValue || counter.presetValue) - 1;
        }
        if (counter.currentValue <= 0) {
          counter.currentValue = 0;
          if (counter.state !== 2) {
            counter.state = 2;
            const linkedContact = findElementById(counter.linkedId);
            if (linkedContact && counter.previousState !== 2) { // Only toggle if previous state was not 2
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle the linked contact state
            }
          }
        }
      }
      counter.previousState = counter.state; // Update the previous state
      console.log(`CTD Counter after update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);

      const element = findElementById(counter.id);
      if (element) {
        element.currentValue = counter.currentValue;
        element.state = counter.state;
        element.previousState = counter.previousState;
      }
    });
  }


    function resetElementCounters() {
  // Reset all counters to 0
  Object.keys(elementCounters).forEach(key => {
    elementCounters[key] = 0;
  });
}



      // Check if the element is selected
    function isSelectedElement(elementId) {
      return selectedElement.value === elementId;
    }
    function toggleSimulation() {
  if (isSimulating.value) {
    stopSimulation();
  } else {
    runSimulation();
  }
}

    // Computed property to display elapsed time as an integer
    const displayElapsedTime = (element) => {
      return Math.floor(element.elapsedTime);
    };

    let timerInterval = null;
let lastUpdateTime = performance.now();

function startTimerIntervals() {
  clearInterval(timerInterval);

  timerInterval = setInterval(() => {
    const currentTime = performance.now();
    const elapsedTime = (currentTime - lastUpdateTime) / 1000; // Convert to seconds

    lastUpdateTime = currentTime; // Update the last update time to current

    timers.timer_ton.forEach(timer => {
      if (timer.state === 1 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

    timers.timer_tof.forEach(timer => {
      if (timer.state === 2 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

    timers.timer_tonr.forEach(timer => {
      if (timer.state === 1 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

  // Update counters   
  updateCounters();
    // Your function to calculate energized states
    calculateEnergizedStates();
  }, 100); // Update interval of 100ms
}


function toggleContact(element) {
  if (isSimulating.value) {
    if (element.type === 'contact_no' || element.type === 'contact_nc') {
      element.state = element.state === 0 ? 1 : 0;
      console.log(`Toggled ${element.type} ${element.id} to state: ${element.state}`);
    } else if (element.type === 'contact_pos') {
      element.state = 1;
      setTimeout(() => {
        element.state = 0;
        calculateEnergizedStates(); // Recalculate the energized states after resetting
        //console.log(`Reset ${element.type} ${element.id} to state: ${element.state}`);
      }, 100); // Delay to simulate the one-shot behavior
    } else if (element.type === 'contact_neg') {
      element.state = 0;
      setTimeout(() => {
        element.state = 1;
        calculateEnergizedStates(); // Recalculate the energized states after resetting
       // console.log(`Reset ${element.type} ${element.id} to state: ${element.state}`);
      }, 100); // Delay to simulate the one-shot behavior
    }

    // Ensure UI reflects the state change
    rungs.value = [...rungs.value];  // Trigger reactivity

    calculateEnergizedStates();  // Recalculate the energized states after toggling
  }
}

function getElementColor(element) {
  if (!isSimulating.value) {
    return 'rgb(3, 171, 255)'; // Default color when not simulating
  }

  if (element.type === 'contact_no' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_nc' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_pos' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_neg' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'r_coil' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil_set' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil_reset' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_ton' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_tof' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_tonr' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctu' && element.state === 2) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctd' && element.state === 2) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctud' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  return 'rgb(3, 171, 255)'; // Default color for other states or types
}

function getSegmentColor(rungIndex, segmentId) {
  if (!isSimulating.value) {
    return 'rgb(3, 171, 255)'; // Default color when not simulating
  }

  const rung = rungs.value[rungIndex];
  if (!rung || !rung.segments) {
    return 'rgb(3, 171, 255)'; // Default color if segments are undefined
  }

  // Check if the segment is a vertical branch
  const isVerticalBranch = segmentId.startsWith('branch-vertical');
  const segment = rung.segments.find(seg => seg.id === segmentId) ||
                  rung.elements.find(el => el.id === segmentId);

  if (segment) {
    // If it's a vertical branch, ensure we get its state from the `calculateEnergizedStates` logic
    if (isVerticalBranch) {
      return segment.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)'; // Energized or default color based on state
    } else {
      return segment.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)';  // Energized or default color based on state
    }
  }

  return 'rgb(3, 171, 255)';  // Default color
}




    function isRungEnergized(rungIndex) {
      return energizedRungs.value.includes(rungIndex);
    }

    function calculateEnergizedStates() {
  rungs.value.forEach((rung, rungIndex) => {
    // Step 1: Get all elements without filtering out vertical branches
    const nonBranchElements = rung.elements.filter(el => el.type !== 'branch_horizontal');
    console.log('Non-branch elements:', JSON.parse(JSON.stringify(nonBranchElements)));

    // Step 2: Get computed segments without filtering out horizontal branch segments
    const computedSegments = computed(() => {
      if (!rungs.value) return [];
      console.log('CES- Computing segments for rungs:', JSON.parse(JSON.stringify(rungs.value)));
      return rungs.value.map((rung, index) => {
        console.log(`CES- Computing segments for rung ${index}:`, JSON.parse(JSON.stringify(rung)));
        const segments = getLineSegments(rung.elements || []);
        console.log(`CES--Segments for rung ${index}:`, JSON.parse(JSON.stringify(segments)));
        return {
          ...rung,
          elements: rung.elements || [],
          segments,
        };
      });
    });

    const segments = computedSegments.value[rungIndex]?.segments || [];
    console.log(`CES- Segments for rung ${rungIndex}:`, JSON.parse(JSON.stringify(segments)));

    // Step 3: Identify branch structures including vertical branch elements
    const branchStructures = [];
    const branchStarts = rung.elements.filter(el => el.id.startsWith('branch-vertical-start'));
    const branchEnds = rung.elements.filter(el => el.id.startsWith('branch-vertical-end'));



    branchStarts.forEach(branchStart => {
      const branchLevel = branchStart.level;

      // Find the corresponding branch end based on position and level
      const branchEnd = branchEnds.find(endEl => endEl.level === branchLevel && endEl.position > branchStart.position);

      console.log('Branch Start:', JSON.parse(JSON.stringify(branchStart)));
      console.log('Branch End:', JSON.parse(JSON.stringify(branchEnd)));

      if (branchEnd) {
        const branchLength = (branchEnd.position - branchStart.position) / 72;

        // Include all elements in the branch structure
        const branchElements = rung.elements.filter(el => {
          const isInBranch = el.position >= branchStart.position && el.position <= branchEnd.position;
          return isInBranch && el.level === branchLevel;
        });

        branchStructures.push({
          level: branchLevel,
          start: branchStart,
          end: branchEnd,
          length: branchLength,
          elements: branchElements,  // Include vertical branches and other elements
        });

        console.log(`Branch found: Level ${branchLevel}, Start Position ${branchStart.position}, End Position ${branchEnd.position}, Length ${branchLength}`);
        console.log(`Branch elements:`, JSON.parse(JSON.stringify(branchElements)));
      }
    });
    console.log('branchStructures:', JSON.parse(JSON.stringify(branchStructures)));
    // Step 4: Combine segments, non-branch elements, and branches into the matrix
    let combinedElements = [...segments];

    // Insert branches into the combined matrix
    branchStructures.forEach(branch => {
      combinedElements.push(...branch.elements);
    });

    // Filter out non-branch elements that are part of branches from the main rung matrix
    combinedElements.push(...nonBranchElements.filter(el => {
      return !branchStructures.some(branch => branch.elements.includes(el));
    }));

    // Step 5: Sort combined elements by their position
    combinedElements.sort((a, b) => {
      const getPosition = (element) => {
        if (element.x1 !== undefined) return element.x1;
        if (element.position !== undefined) return element.position;
        if (element.elements && element.elements.length > 0) return element.elements[0]?.position;
        return Infinity; // Use Infinity as a fallback for undefined positions to push these elements to the end
      };

      const aPos = getPosition(a);
      const bPos = getPosition(b);

      return aPos - bPos;
    });

    // Step 6: Create the final matrix with full object references
    let rungMatrix = combinedElements.map(el => el);

   // Now, split the matrix into chunks based on branch structures
   const populatedChunks = splitRungMatrix(rungMatrix, branchStructures);

  console.log(`Populated Chunks for rung ${rungIndex}:`, JSON.stringify(populatedChunks, null, 2));


// Step 7: Energy Propagation Logic with Chunk and Branch Handling
let mainRungEnergyFlow = 1; // Start with the assumption that energy flows
let previousChunkAllowedFlow = false; // Track if the previous chunk allowed energy flow

// Use a for loop to allow breaking out of the loop when energy is blocked
for (let chunkIndex = 0; chunkIndex < populatedChunks.length; chunkIndex++) {
  const chunk = populatedChunks[chunkIndex];
  let chunkEnergyFlow = mainRungEnergyFlow; // Current energy flow for the chunk
  let chunkAllowsEnergyFlow = false; // Determines if energy can flow through this chunk
  let chunkLogs = []; // Logs for the current chunk

  console.log(`\nProcessing Chunk ${chunkIndex + 1}...`);

  // Energize vertical branch starts if the previous chunk allowed energy flow
  chunk.forEach(element => {
    if (element.type === 'branch_vertical' && element.id.includes('start')) {
      element.state = previousChunkAllowedFlow ? 1 : 0;
      chunkLogs.push(`Chunk ${chunkIndex + 1} Level ${element.level}: ${element.type} (start) at ${element.position || element.x1} - State: ${element.state}`);
    }
  });

  // Identify if the chunk contains any branch elements
  const chunkHasBranch = chunk.some(element => element.type.includes('branch_vertical') || element.type.includes('branch_horizontal'));

  if (chunkHasBranch) {
    console.log("Branches detected in chunk. Processing branches...");

    // Group elements by their levels (paths)
    const elementsByLevel = chunk.reduce((acc, element) => {
      const level = element.level || 0;
      if (!acc[level]) {
        acc[level] = [];
      }
      acc[level].push(element);
      return acc;
    }, {});

    let anyLevelAllowsFlow = false; // Tracks if any branch allows flow

    // Process each level independently
    Object.keys(elementsByLevel).forEach(level => {
      const elements = elementsByLevel[level];
      let levelFlow = chunkEnergyFlow; // Energy flow for this level
      let levelAllowsEnergyFlow = false; // Track if this level allows energy flow


      elements.forEach(element => {
        if (element.type === 'segment') {
          element.state = levelFlow; // Segments propagate energy flow
        } else if (element.type === 'contact_no') {
          // Normally open contact blocks energy if not energized
          levelFlow = element.state === 1 ? levelFlow : 0;
          element.state = levelFlow;
        } else if (element.type === 'branch_horizontal') {
          // Branch elements can also block or allow flow
          levelFlow = element.state === 1 ? levelFlow : 0;
          element.state = levelFlow;
        } else {
          // Handle other element types as needed
          levelFlow = element.state === 1 ? levelFlow : 0;
          element.state = levelFlow;
        }

        // Log each element's state
        chunkLogs.push(`Chunk with Branch ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
      });

      // Determine if this level (path) allows energy flow through the entire level
      if (levelFlow === 1) {
        anyLevelAllowsFlow = true;
        console.log(`anyLevelAllowsFlow  ${chunkIndex + 1} Level ${level} = ${anyLevelAllowsFlow }`);
      }

            // If any level allows energy flow, set the flag for the entire chunk
      if (levelAllowsEnergyFlow) {
        anyLevelAllowsFlow = true;
      }

      // Log the result for this level
      const levelFinalFlow = levelFlow === 1 ? "Energized, let energy flow" : "Not Energized, stop energy flow";
      console.log(`Chunk with Branch Result ${chunkIndex + 1} Level ${level} = ${levelFinalFlow}`);
    });

    // After processing all levels, determine if any branch allows flow
    chunkAllowsEnergyFlow = anyLevelAllowsFlow;

    // Update the main rung's energy flow based on whether any branch allows flow
    mainRungEnergyFlow = chunkAllowsEnergyFlow ? 1 : 0;

  } else {
    // No branches in this chunk; process sequentially
    let levelFlow = chunkEnergyFlow; // Energy flow for the main path

    chunk.forEach(element => {
      if (element.level === 0) { // Only process main path
        if (element.type === 'segment') {
          element.state = levelFlow; // Segments propagate energy flow
        } else if (element.type === 'contact_no') {
          // Normally open contact blocks energy if not energized
          levelFlow = element.state === 1 ? levelFlow : 0;
          element.state = levelFlow;
        } else {
          // Handle other element types as needed
          levelFlow = element.state === 1 ? levelFlow : 0;
          element.state = levelFlow;
        }

        // Log each element's state
        chunkLogs.push(`Chunk ${chunkIndex + 1} Level 0: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
      }
    });

    // Determine if the main path allows energy flow
    chunkAllowsEnergyFlow = (levelFlow === 1);

    // Log the result for the whole chunk
    const chunkFinalFlow = chunkAllowsEnergyFlow ? "Energized, let energy flow" : "Not Energized, stop energy flow";
    console.log(`Chunk ${chunkIndex + 1} Level 0 = ${chunkFinalFlow}`);

    // Update the main rung's energy flow based on the main path
    mainRungEnergyFlow = chunkAllowsEnergyFlow ? 1 : 0;
  }

  // Energize vertical branch ends if this chunk allows energy flow
  chunk.forEach(element => {
    if (element.type === 'branch_vertical' && element.id.includes('end')) {
      element.state = chunkAllowsEnergyFlow ? 1 : 0;
      chunkLogs.push(`Chunk ${chunkIndex + 1} Level ${element.level}: ${element.type} (end) at ${element.position || element.x1} - State: ${element.state}`);
    }
  });

  // Output the logs for this chunk
  chunkLogs.forEach(log => console.log(log));

  // Set the flag to track if the current chunk allowed energy flow
  previousChunkAllowedFlow = chunkAllowsEnergyFlow;

  // Even if energy is blocked, continue processing the rest of the chunks and de-energize them
  if (!chunkAllowsEnergyFlow) {
    mainRungEnergyFlow = 0; // Explicitly stop energy flow
  }
}




    // Step 8: Update the actual rung segments with the calculated states
    rung.segments.forEach((segment) => {
      const matrixElement = rungMatrix.find(el => el.id === segment.id);
      if (matrixElement) {
        segment.state = matrixElement.state;
        console.log(`CES 8- Segment ${segment.id} updated with state: ${segment.state}`);
      }
    });

    // Update the UI or state based on energy flow
    rungMatrix.forEach((element, index) => {
      console.log(`Element ${index} (${element.id || element.type}) final state: ${element.state}`);
    });
  });
}



function splitRungMatrix(rungMatrix, branchStructures) {
  const chunks = [];
  const branchPositions = []; // Array to store branch start and end positions

  // Step 1: Identify vertical branch start and end positions
  branchStructures.forEach((branch, index) => {
    const branchStartPos = branch.start.position;
    const branchEndPos = branch.end.position;
    branchPositions.push({ rungIndex: index, x1: branchStartPos, x2: branchEndPos });
    console.log(`Branch ${index + 1} found: Start Position ${branchStartPos}, End Position ${branchEndPos}`);
  });

  console.log("Branch Positions:", JSON.stringify(branchPositions));

  // Step 2: Calculate the chunks based on branch positions
  let currentPos = 0;

  branchPositions.forEach((branch, index) => {
    const previousEndPos = index > 0 ? branchPositions[index - 1].x2 : 0;
    
    if (previousEndPos !== branch.x1) {
      chunks.push({ x1: previousEndPos, x2: branch.x1 });
      console.log(`Chunk ${chunks.length + 1} created from position ${previousEndPos} to ${branch.x1}`);
    }

    chunks.push({ x1: branch.x1, x2: branch.x2 });
    console.log(`Chunk ${chunks.length + 1} created from position ${branch.x1} to ${branch.x2}`);
    
    currentPos = branch.x2;
  });

  // Add the final chunk from the last branch end to the end of the rung
  const lastPosition = rungMatrix[rungMatrix.length - 1].position || rungMatrix[rungMatrix.length - 1].x2;
  if (currentPos < lastPosition) {
    chunks.push({ x1: currentPos, x2: lastPosition });
    console.log(`Final chunk created from position ${currentPos} to ${lastPosition}`);
  }

  console.log("Chunks Information:", JSON.stringify(chunks));

// Step 3: Populate the chunks with elements
const populatedChunks = chunks.map((chunk, index) => {
  const elementsInChunk = rungMatrix.filter((element) => {
    const elementPos = element.position || element.x1;

    // Only include elements whose x1 is within the chunk's boundaries
    if (elementPos >= chunk.x1 && elementPos < chunk.x2 && element.type !== 'branch_vertical') {
      return true;
    }

    // Ensure vertical start branches are correctly placed
    if (element.type === 'branch_vertical' && elementPos === chunk.x1 && element.id.startsWith('branch-vertical-start')) {
      console.log("branch vertical start present");
      return true;
    }

    // Ensure vertical end branches are correctly placed
    if (element.type === 'branch_vertical' && elementPos === chunk.x2 && element.id.startsWith('branch-vertical-end')) {
      console.log("branch vertical end present");
      return true;
    }

    // Exclude elements that belong to the previous chunk if they match chunk.x2 but are not vertical branch ends
    if (elementPos === chunk.x2 && !element.id.startsWith('branch-vertical-end')) {
      return false;
    }

    return false;
  });

  console.log(`Chunk ${index + 1}:`, JSON.stringify(elementsInChunk, null, 2));
  return elementsInChunk;
});


  return populatedChunks;
}







































    return {
      //here we have all the return for the header menu
      showTooltip,
      hideTooltip,
      logo,
      undo,
      redo,
      newProjectDialog,
      confirmNewProject,
      runSimulation,
      stopSimulation,
      simulationAlertDialog,

      //here we have all the return for the left-column tools menu
      showTooltip2,
      tooltipText,
      tooltipVisible,
      tooltipX,
      tooltipY,
      tab,
      variables,
      tools,

      //here we have all the return for the right-column ladder logic area
      rungs,
      addToolToRung,
      getLineSegments,
      isSegmentOverlappingTool,
      filteredSegments,
      getRungWidth,
      deleteVariable,
      deleteElement,
      ladderLogicArea,
      ladderLogicWidth,
      selectElement,
      selectedElement,
      selectSegment,
      selectedSegment,
      segmentCount,
      getPositionX,
      computedSegments,
      computedWidth,
      updateSegments,
      startDrag,
      handleDrop,
      dropOnSegment,
      addRung,
      selectedRung,
      updateVariable,
      isSelectedElement,
      isSimulating,
      energizedSegments,
      toggleSimulation,
      toggleContact,
      getSegmentColor,
      isRungEnergized,
      addToolDialog,
      getElementColor,
      addRungDialog,
      showTimerDialog,
      dialogPresetTime,
      dialogLinkedContact,
      availableContacts,
      configureTimer,
      openTimerDialog,
      closeTimerDialog,
      saveTimerSettings,
      findElementById,
      startTimerIntervals,
      displayElapsedTime,
      initializeCounters,
      updateCounters,
      showCounterDialog,
      dialogPresetValue,
      configureCounter,
      closeCounterDialog,
      saveCounterSettings,
      calculateEnergizedStates,
      computedRungHeights,
      calculateRungHeight,
     // New returns for the "+" button and branch functionality
      showPlusButton,
      plusButtonPosition,
      selectedBranchElement,
      addBranch,
      getElementPosition,
      getSegmentPosition,
      computedVerticalBranches,
      computedHorizontalBranches,
      isBranchSegment,
      nonBranchSegments,
      nonBranchElements,
      deleteButtonCondition,
      deleteSelectedBranch,
      currentElement,
      currentRungIndex, 
      deleteButtonPosition, 
      extendBranch,
      showExtendBranchButton,
      extendBranchButtonPosition,
      showDeleteBranchButton,
      getRungBaseHeight,
      energizeVerticalBranchSegments,
      splitRungMatrix,
    };
  },
};
</script>

<style scoped>
.tooltip-box {
  position: fixed;
  background: #000814;
  color: #ffd60a;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}
.v-app-bar {
  background-color: #000814;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: auto;
  border-bottom: 20px;
  border-bottom-color: #000000;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  height: auto;
}

.custom-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  border-radius: 50%;
  padding: 10px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  transition: background 0.3s;
}

.custom-button:hover {
  filter: brightness(0.9); /* Darker on hover */
}

.custom-button:active {
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.3);
}

.custom-button svg path {
  fill: #000814;
}

.blue-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
}

.yellow-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
}

.pill-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  height: 40px; /* Adjust height to match circular buttons */
  display: flex;
  align-items: center;
  font-weight: bold; /* Bold text */
}

.pill-button:hover,
.pill-button:active {
  filter: brightness(0.9);
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.3);
}

.main-container {
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.inner-container {
  flex: 1;
  display: flex;
  padding-top: 85px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
}

.left-column {
  background: linear-gradient(to bottom, #000814, #000000);
  width: 25%;
  padding: 10px;
  height: calc(100vh - 70px); /* Adjusted height to account for header and gap */
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center; /* Center align items horizontally */
  position: fixed; /* Makes the left column fixed in the viewport */
  top: 70px; /* Adjust this value based on the height of your header + gap */
  left: 0;
  z-index: 100; /* Ensures it stays above other content */
  margin-right: 5px; /* Adds a gap between the left column and the ladder logic area */
}

.ladder-logic-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: calc(25% + 5px); /* Adjust margin to account for the width of the left column + gap */
  background: none;
  overflow-x: auto; /* Scroll bar is now at the bottom */
  overflow-y: scroll;
  position: relative;
  padding-bottom: 20px;
  padding-right: 10px; /* Add padding to the right */
}



.ladder-logic-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; /* Adjust for no overlap */
  margin-right: 5px;
  bottom: 15px; /* Space for scrollbar, matching the padding in the container */
}

.rung-wrapper {
  position: relative;
  width: 100%; /* Ensures width matches the content requirement dynamically */
  overflow-x: auto; /* Ensures scrolling is available */
  min-height: 100px; /* Ensures there's enough height for the content */
  transition: height 0.3s ease-in-out;


  
}

.rung-svg {
  height: 100px;
  overflow: visible; /* Allows SVG to extend beyond the initial container */
  padding-right: 5px; /* Adds padding to the right for better visual spacing */
  transition: height 0.3s ease-in-out;
  margin-top: -30px; /* Negative margin to reduce space above */height: 100px; /* Adjust as needed */overflow: visible; /* Allows SVG to extend beyond the initial container */padding-right: 5px; /* Adds padding to the right for better visual spacing */


}


.add-rung-button {
  margin-top: 5px;
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Darker background for active tab */
  color: #000814; /* Text color for active tab */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.element {
  display: inline-block;
  vertical-align: middle;
}

.company-logo {
  max-height: 100%;
  object-fit: contain;
}
.v-tab {
  width: 100%; /* Ensure tabs fill the column */
  border: none; /* Remove any default border */
  position: relative; /* Ensure position-relative to apply z-index */
  display: flex; /* Ensure content inside the tab is flexible */
  justify-content: center;
}

.v-tab--active {
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Darker background for active tab */
  color: #000814; /* Text color for active tab */
  border: 3px solid #000814 !important; /* Border around the active tab */
}

.v-tab:not(.v-tab--active) {
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Background color for inactive tabs */
  color: #000814; /* Text color for inactive tabs */
}

.full-width-tabs .v-tab {
  flex: 1; /* Make tabs take equal space */
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}

.selected {
  stroke: #ffc300 !important; /* Override other stroke colors when selected */
}

.rung-svg .selected-segment {
  stroke: #ffc300; /* Highlight color */
}

.custom-blue-button,
.custom-yellow-button {
  border-radius: 50%;
  width: 65px; /* Reduce size by 25% */
  height: 65px; /* Ensure equal width and height for a circle */
  padding: 5px; /* Remove padding */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
}


.custom-blue-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
}

.custom-yellow-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
}

.custom-green-button,
.custom-red-button {
  border-radius: 50%;
  padding: 10px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  transition: background 0.3s;
}
.custom-green-button {
  background: linear-gradient(to bottom, #039a01, #03ff03);
  color: #000814;
}

/* New red button styles */
.custom-red-button {
  background: linear-gradient(to bottom, #a10101, #ff0303);
  color: #000814;
}

.section-title {
  color: #ffffff;
  margin-top: 3px;
  text-align: center;
  text-transform: uppercase;
}

.section-line {
  width: 80%;
  height: 1px;
  background-color: #ffffff;
  margin: 0 auto 5px;
  margin-bottom: 5px;
}

.delete-button-fo {
  overflow: visible; /* Ensure the entire button is visible */
}

.delete-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 0px;
}

.delete-button:hover {
  background-color: #ff6666; /* Lighter red color on hover */
}

.v-row {
  display: flex;
  justify-content: space-between; /* Distribute space evenly between buttons */
  width: 100%;
}

.tabs-container {
  width: 100%; /* Ensure the tabs container takes full width */
  margin-bottom: 10px; /* Space between tabs and tools */
  
}
.tabs-container .v-tab {
  font-size: 16px; /* Set your desired font size here */
  font-weight: bold; /* Optional: if you want to make the tab labels bold */
}

/* For all scrollbars */
::-webkit-scrollbar {
  width: 4px; /* Adjust this value to change the width */
  height: 4px; /* Adjust this value to change the height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #000000; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #5c5b5b; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Add rounded corners to the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a; /* Color when the user hovers over the scrollbar thumb */
}

.element-id {
  fill: #ffc300; /* Hex color for the yellow text */
  font-size: 18px; /* Adjust size as needed */
  user-select: none; /* Prevents text from being selected */
}
.variables-table {
  width: 100%; /* Ensures the table occupies the full width of the column */
  border-collapse: collapse; /* Removes any default spacing between borders */
}

.variables-table th, .variables-table td {
  border-right: 1px solid rgb(3, 171, 255); /* Vertical lines */
  padding: 8px; /* Padding inside cells */
  text-align: left; /* Aligns text to the left */
  color: rgb(3, 171, 255); /* Sets the text color */
}

.variables-table th:last-child, .variables-table td:last-child {
  border-right: none; /* Removes the border on the last column */
}

.variables-table tr {
  border-bottom: 3px solid #ffc300; /* Horizontal lines between rows */
}

.variables-table thead tr {
  border-bottom: 6px solid #ffc300; /* Thicker line under the header */
}

.variables-table thead th {
  font-weight: bold; /* Bold font for headers */
  font-size: 18px;
}
.variables-table input, .variables-table select {
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box; /* Makes sure padding does not affect width */
}

.variables-table input[type="text"] {
  text-transform: uppercase; /* Optional: if you want all inputs to be uppercase */
}



/* Style applies to all options */
.variables-table .variable-select option {
  color: #000814; /* Ensures text is always visible against the light background */
  background-color: #ffffff; /* Background color for options */
}

.add-branch-button {
  width: 24px; /* Half the original size */
  height: 24px; /* Half the original size */
  opacity: 0.9; /* Semi-transparent */
  margin-top: 5px;
  position: absolute;
  cursor: pointer;
}
.extend-branch-button {
  width: 24px; /* Half the original size */
  height: 24px; /* Half the original size */
  opacity: 0.9; /* Semi-transparent */
  margin-top: 5px;
  position: absolute;
  cursor: pointer;
}



/* Styles for landscape mode */
@media only screen and (orientation: landscape) {
  /* Your landscape styles here */
  .portrait-warning {
    display: none;
  }
}

/* Styles for portrait mode */
@media only screen and (orientation: portrait) {
  /* Hide main content and show a warning in portrait mode */
  .main-container {
    display: none;
  }

  .portrait-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  stroke: #ffd60a;
  text-align: center;
  padding: 20px;
}

.portrait-warning-logo {
  position: bottom;
  padding: 150px;
}

.svg-icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.rotate-message {
  font-size: 24px;
}

}

.v-row--no-gutters {
  background: transparent !important;
}

/* Add the necessary styles for highlighting */
.highlight-id-class-type-cps td:nth-child(1), /* ID */
.highlight-id-class-type-cps td:nth-child(3), /* Class */
.highlight-id-class-type-cps td:nth-child(4), /* Type */
.highlight-id-class-type-cps td:nth-child(6) { /* CPS */
  color: #ffc300;
}

.highlight-name-value td:nth-child(2), /* Name */
.highlight-name-value td:nth-child(5) { /* Value */
  color: rgb(3, 171, 255);
}

  /* Hide the next and previous navigation controls in Vuetify tabs */

:deep(.v-slide-group__controls .v-slide-group__prev),
:deep(.v-slide-group__controls .v-slide-group__next) {
  display: none !important;
}

/* Media query for devices with width below 1050px */
@media (max-width: 1300px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 20px;
  }
}

/* Media query for devices with width below 1050px */
@media (max-width: 1250px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 25px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 1200px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 35px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 1150px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 50px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 1100px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 60px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 1050px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 70px;
  }
}
/* Media query for devices with width below 1050px */
@media (max-width: 1000px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 80px;
  }
}

/* Media query for devices with width below 1050px */
@media (max-width: 950px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 97px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 900px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 110px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 850px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 122px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 800px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 135px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 750px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 146px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 700px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 160px;
  }
}
</style>